import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, UrlTree, Router } from '@angular/router';
import { LinkedTaskService, ProjectTaskService } from '../services';
import { ERROR_STATUS, FOUND_PAGE } from '../enums';

const REDIRECT_APAGE = '/404';
@Injectable({
  providedIn: 'root',
})
export class TaskGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _projectTaskService: ProjectTaskService,
    private _linkedTaskService: LinkedTaskService
  ) {}

  private _checkError(err) {
    if (err.status === ERROR_STATUS.NOT_FOUND) {
      this._notFound();
      return false;
    }

    if (err.status === ERROR_STATUS.NOT_AUTHORIZED) {
      this._notAuthorized();
      return false;
    }

    this._unknown();
    return false;
  }

  private _notAuthorized() {
    this._router.navigate([REDIRECT_APAGE], {
      queryParams: {
        status: FOUND_PAGE.NOT_AUTHORIZED,
      },
    });
  }

  private _notFound() {
    this._router.navigate([REDIRECT_APAGE], {
      queryParams: {
        status: FOUND_PAGE.NOT_FOUND,
      },
    });
  }

  private _unknown() {
    this._router.navigate([REDIRECT_APAGE], {
      queryParams: {
        status: FOUND_PAGE.UN_KNOWN,
      },
    });
  }

  public async canActivate(next: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    if (next.params?.task_id) {
      return this._projectTaskService
        .loadTaskSuppressed(next.params?.task_id, ['id', 'project_id'])
        .toPromise()
        .then(({ id, project_id }) => {
          this._router.navigate([`/projects/${project_id}/tasks/${id}`], {
            queryParams: {
              task: 'valid',
            },
          });
          return true;
        })
        .catch((err) => {
          return this._checkError(err);
        });
    } else if (next.params?.linked_task_id) {
      return this._linkedTaskService
        .loadLinkedTaskSuppressed(next.params?.linked_task_id, ['id', 'project_id'])
        .toPromise()
        .then(({ id, project_id }) => {
          this._router.navigate([`/projects/${project_id}/linked-tasks/${id}`], {
            queryParams: {
              task: 'valid',
            },
          });
          return true;
        })
        .catch((err) => {
          return this._checkError(err);
        });
    }
  }
}
