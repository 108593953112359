// organize-imports-ignore
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { GoogleChartsModule } from 'angular-google-charts';
import { AngularSplitModule } from 'angular-split';
import { NgxDropzoneModule } from 'ngx-dropzone';
// components
import {
  AccountReviewDialogComponent,
  AddFollowersComponent,
  AddSubmittalsDialogComponent,
  AgendaItemDialogComponent,
  AidetComponent,
  ArfExportComponent,
  ArfProductComponent,
  ArfPurchaseTypeDialogComponent,
  ArfReviewDialogComponent,
  ARManagerDialogComponent,
  AssignedButtonComponent,
  AssignUserButtonComponent,
  AssignUserDialogComponent,
  BarChartComponent,
  BaseDialogComponent,
  BidContractExportComponent,
  BidPackageInvoiceMenuComponent,
  BudgetReportExportComponent,
  BugReportDialogComponent,
  BuildingComponent,
  BuildingManagerComponent,
  BuildingManagerDialogComponent,
  CapxPanelComponent,
  CheckboxContainerComponent,
  CompanyDialogComponent,
  CompanyProfileDocumentsDialogComponent,
  ConcludeMeetingDialogComponent,
  ConfirmationChoiceDialogComponent,
  ConfirmationDialogComponent,
  CostCodeHelpDialogComponent,
  CostCodeSelectionComponent,
  CostCodeSelectionFormComponent,
  CoverLetterDialogComponent,
  CqiDialogComponent,
  CreateTaskDialogComponent,
  CustomerDialogComponent,
  DatepickerHeaderComponent,
  DatepickerHeaderTemplateCommonComponent,
  DepartmentComponent,
  DepartmentManagerComponent,
  DepartmentManagerDialogComponent,
  DepartmentManagerDialogSuiteComponent,
  DispatchActivitiesComponent,
  DispatchRequestComponent,
  DispatchTopicComponent,
  DonutChartComponent,
  EditNoteDialogComponent,
  EditStaffDialogComponent,
  EditorComponent,
  FileApprovalReviewDialogComponent,
  FileAttachmentDialogComponent,
  FileChipComponent,
  FileChipListComponent,
  FilePreviewComponent,
  FilePreviewDialogComponent,
  FileRenameDialogComponent,
  FileSelectApprovalReviewDialogComponent,
  FileSelectModalComponent,
  FilesUploadModalComponent,
  FileThumbnailComponent,
  FileUploadZoneComponent,
  FloorComponent,
  FloorDialogComponent,
  FormComponent,
  HowToGuideDialogComponent,
  InviteUserDialogComponent,
  InvoiceAccessoryDataComponent,
  InvoiceCoverLetterComponent,
  InvoiceDataComponent,
  InvoiceMenuComponent,
  InvoiceReviewButtonsComponent,
  KeyControlIconBadgeComponent,
  LatestUpdateComponent,
  LocationComponent,
  LoginDialogComponent,
  MeetingAgendaItemNoteComponent,
  MeetingDialogComponent,
  MeetingExportDialogComponent,
  MeetingSelectDialogComponent,
  MessagingConversationPanelComponent,
  MessagingConversationPanelMessageComponent,
  MessagingCreateConversationPanelComponent,
  MessagingInboxConversationBoxComponent,
  MessagingInboxDisplayComponent,
  MessagingInboxMessageBoxComponent,
  MessagingOverlayPanelComponent,
  MilestoneDialogComponent,
  MilestoneTaskContainerComponent,
  NewAccountModalComponent,
  NewConversationMessageComponent,
  NewGuestAccountModalComponent,
  NewInvoiceDialogComponent,
  NewTaskViewComponent,
  NotificationDialogComponent,
  NotificationOverlayPanelComponent,
  OpenTaskButtonComponent,
  PasswordResetDialogComponent,
  ProductDialogComponent,
  ProfileInfoComponent,
  ProfileThumbnailComponent,
  ProgressIndicatorComponent,
  ProjectDetailsDialogComponent,
  ProjectFollowersComponent,
  ProjectHeaderComponent,
  ProjectNavComponent,
  ProjectProductComponent,
  ProjectProductDialogComponent,
  ProjectTaskPanelComponent,
  ProjectTopicComponent,
  ProjectUpdateDialogComponent,
  QuoteDialogComponent,
  ReminderDialogComponent,
  RequestAccountDialogComponent,
  RequestCategoryComponent,
  RequestMethodComponent,
  RequestReviewDialogComponent,
  RequestTopicComponent,
  ResourceSearchComponent,
  ReviewProgressComponent,
  ReviewSidebarComponent,
  SearchOverlayPanelComponent,
  SearchUserInputComponent,
  SelectUserFromListDialogComponent,
  SidenavComponent,
  SplitButtonComponent,
  StringListPillContainerComponent,
  SubmittalButtonsComponent,
  SubmittalTransmittalComponent,
  SuiteComponent,
  SuitesManagerComponent,
  SuitesManagerDialogComponent,
  TaskAccessoryComponent,
  TaskReviewDialogComponent,
  TaskStatusIconComponent,
  TaskViewActivityComponent,
  TaskViewComponent,
  TimeLogDialogComponent,
  TimeLogPanelComponent,
  TimerComponent,
  TitleSeventyFourKeyControlColumnsComponent,
  TitleSixyOneKeyControlColumnsComponent,
  TopicDialogComponent,
  TopicGroupComponent,
  TransferWorkOrderDialogComponent,
  TroubleshootLoginDialogComponent,
  UpdateAvailableComponent,
  UploadSubmittalsDialogComponent,
  UserProfilePreviewComponent,
  UserSearchComponent,
  UserSelectDropdownComponent,
  UserSelectModalComponent,
  ViewInvoiceDialogComponent,
  ViewKeyControlsDialogComponent,
  ViewMeetingDialogComponent,
  ViewQuoteDialogComponent,
  ViewTaskDialogComponent,
  WorkOrderChecklistComponent,
  WorkOrderDetailsPanelComponent,
  WorkOrderDialogComponent,
  WorkOrderHeaderComponent,
  WorkOrderTopicComponent,
  WorkOrderUpdateDialogComponent,
  WorkspaceDropdownComponent,
  WorkspaceSelectorComponent,
  YesNoSelectionComponent,
  DatepickerHeaderNoClearComponent,
} from 'src/app/components';
// directives
import { AutofocusDirective, TextCollapseExpandDirective, UniqueEmailDirective } from 'src/app/directives';
import { MaterialModule } from 'src/app/modules/material.module';
// pages
// TODO remove with the below todo
import {
  ArfComponent,
  ArfListComponent,
  FilesComponent,
  InvoicesComponent,
  PurchasingInvoiceListComponent,
  PurchasingBudgetListComponent,
  ProjectBudgetCbComponent,
  ProjectBudgetComponent,
  ProjectDirectoryComponent,
  TasksComponent,
  ConstructionAccessComponent,
} from 'src/app/pages';
// pipes
import {
  AgendaFilterPipe,
  ArfInvoicesPipe,
  ArfStatusCountPipe,
  GetArfProductsTotalPricePipe,
  DecimalPipe,
  DirectoryUsersFilterPipe,
  DispatchFilterPipe,
  DispatchSortPipe,
  FilenameFilterPipe,
  FilterBySelectedTagsPipe,
  FilterCostCodeFormArrayBySearchTermPipe,
  FilterCostCodeBySearchTermPipe,
  FilterCostCodesByBudgetIsEnabledPipe,
  FilterPipe,
  FormatMeetingTimePipe,
  GetArfProductSubCostCodesPipe,
  HighlightPipe,
  HtmlEncodeDecodePipe,
  InvoiceFilterPipe,
  InvoiceSortPipe,
  IsEnabledPipe,
  LogPipe,
  MeetingDateFilterPipe,
  MeetingListFilterPipe,
  MessageFilterPipe,
  MilestonesFilterPipe,
  OrderByFieldPipe,
  OrdinalPipe,
  PadIdPipe,
  PaginatorPipe,
  PhoneNumberPipe,
  ProductFilterPipe,
  ProjectInvoiceTotalsPipe,
  ProjectListFilterPipe,
  ProjectUsersFilterPipe,
  PurchasingInvoiceListStatusCountPipe,
  RequestListFilterPipe,
  ReversePipe,
  SafeHtmlPipe,
  StringShrinkPipe,
  StripHtmlPipe,
  SubmittalsFilterPipe,
  TaskActivityFilterPipe,
  TaskListsPipe,
  TasksFilterPipe,
  TimeLogFilterPipe,
  TruncatePipe,
  USDollarPipe,
  UserListPipe,
  UserNameFilterPipe,
  UserSearchFilterPipe,
  WorkOrderListFilterPipe,
  AsFormGroupPipe,
  AsFormArrayPipe,
  IsJsonEquivalentPipe,
  FilterCostCodeFormArrayByModuleIdPipe,
  FilterSubCostCodeFormArrayByModuleIdPipe,
} from 'src/app/pipes';
import { MatSortModule } from '@angular/material/sort';
import { CdkTableModule } from '@angular/cdk/table';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { AutoCompleteComponent } from '../components/auto-complete/auto-complete.component';

@NgModule({
  declarations: [
    // TODO move this into app module as a page when we find a solution
    // currently moving it would break the app due to construction module needing to import
    // appmodule for access to tasks.component, but app module also imports constructionmodule
    ArfComponent,
    ArfListComponent,
    FilesComponent,
    InvoicesComponent,
    TasksComponent,
    PurchasingInvoiceListComponent,
    PurchasingBudgetListComponent,
    ProjectDirectoryComponent,
    ProjectBudgetComponent,
    ProjectBudgetCbComponent,

    // components
    AccountReviewDialogComponent,
    AddFollowersComponent,
    AddSubmittalsDialogComponent,
    AgendaItemDialogComponent,
    AidetComponent,
    ArfExportComponent,
    ArfProductComponent,
    ArfPurchaseTypeDialogComponent,
    ArfReviewDialogComponent,
    ARManagerDialogComponent,
    AssignedButtonComponent,
    AssignUserButtonComponent,
    AssignUserDialogComponent,
    AutoCompleteComponent,
    BarChartComponent,
    BaseDialogComponent,
    BidContractExportComponent,
    BidPackageInvoiceMenuComponent,
    BudgetReportExportComponent,
    BugReportDialogComponent,
    BuildingComponent,
    BuildingManagerComponent,
    BuildingManagerDialogComponent,
    CapxPanelComponent,
    CheckboxContainerComponent,
    CompanyDialogComponent,
    CompanyProfileDocumentsDialogComponent,
    ConcludeMeetingDialogComponent,
    ConfirmationChoiceDialogComponent,
    ConfirmationDialogComponent,
    CostCodeHelpDialogComponent,
    CostCodeSelectionComponent,
    CostCodeSelectionFormComponent,
    CoverLetterDialogComponent,
    CqiDialogComponent,
    CreateTaskDialogComponent,
    CustomerDialogComponent,
    DatepickerHeaderComponent,
    DatepickerHeaderNoClearComponent,
    DatepickerHeaderTemplateCommonComponent,
    DepartmentComponent,
    DepartmentManagerComponent,
    DepartmentManagerDialogComponent,
    DepartmentManagerDialogSuiteComponent,
    DispatchActivitiesComponent,
    DispatchRequestComponent,
    DispatchTopicComponent,
    DonutChartComponent,
    EditNoteDialogComponent,
    EditStaffDialogComponent,
    EditorComponent,
    FileApprovalReviewDialogComponent,
    FileAttachmentDialogComponent,
    FileChipComponent,
    FileChipListComponent,
    FilePreviewComponent,
    FilePreviewDialogComponent,
    FileRenameDialogComponent,
    FileSelectApprovalReviewDialogComponent,
    FileSelectModalComponent,
    FileThumbnailComponent,
    FileUploadZoneComponent,
    FilesUploadModalComponent,
    FloorComponent,
    FloorDialogComponent,
    FormComponent,
    ConstructionAccessComponent,
    HowToGuideDialogComponent,
    InviteUserDialogComponent,
    InvoiceAccessoryDataComponent,
    InvoiceCoverLetterComponent,
    InvoiceDataComponent,
    InvoiceMenuComponent,
    InvoiceReviewButtonsComponent,
    KeyControlIconBadgeComponent,
    LatestUpdateComponent,
    LocationComponent,
    LoginDialogComponent,
    MeetingAgendaItemNoteComponent,
    MeetingDialogComponent,
    MeetingExportDialogComponent,
    MeetingSelectDialogComponent,
    MessagingConversationPanelComponent,
    MessagingConversationPanelMessageComponent,
    MessagingCreateConversationPanelComponent,
    MessagingInboxConversationBoxComponent,
    MessagingInboxDisplayComponent,
    MessagingInboxMessageBoxComponent,
    MessagingOverlayPanelComponent,
    MilestoneDialogComponent,
    MilestoneTaskContainerComponent,
    NewAccountModalComponent,
    NewConversationMessageComponent,
    NewGuestAccountModalComponent,
    NewInvoiceDialogComponent,
    NewTaskViewComponent,
    NotificationDialogComponent,
    NotificationOverlayPanelComponent,
    OpenTaskButtonComponent,
    PasswordResetDialogComponent,
    ProductDialogComponent,
    ProfileInfoComponent,
    ProfileThumbnailComponent,
    ProgressIndicatorComponent,
    ProjectDetailsDialogComponent,
    ProjectFollowersComponent,
    ProjectHeaderComponent,
    ProjectNavComponent,
    ProjectProductComponent,
    ProjectProductDialogComponent,
    ProjectTaskPanelComponent,
    ProjectTopicComponent,
    ProjectUpdateDialogComponent,
    QuoteDialogComponent,
    ReminderDialogComponent,
    RequestAccountDialogComponent,
    RequestCategoryComponent,
    RequestMethodComponent,
    RequestReviewDialogComponent,
    RequestTopicComponent,
    ResourceSearchComponent,
    ReviewProgressComponent,
    ReviewSidebarComponent,
    SearchOverlayPanelComponent,
    SearchUserInputComponent,
    SelectUserFromListDialogComponent,
    SidenavComponent,
    SplitButtonComponent,
    StringListPillContainerComponent,
    SubmittalButtonsComponent,
    SubmittalTransmittalComponent,
    SuiteComponent,
    SuitesManagerComponent,
    SuitesManagerDialogComponent,
    TaskAccessoryComponent,
    TaskReviewDialogComponent,
    TaskStatusIconComponent,
    TaskViewActivityComponent,
    TaskViewComponent,
    TitleSeventyFourKeyControlColumnsComponent,
    TitleSixyOneKeyControlColumnsComponent,
    TimeLogDialogComponent,
    TimeLogPanelComponent,
    TimerComponent,
    TopicDialogComponent,
    TopicGroupComponent,
    TransferWorkOrderDialogComponent,
    TroubleshootLoginDialogComponent,
    UpdateAvailableComponent,
    UploadSubmittalsDialogComponent,
    UserProfilePreviewComponent,
    UserSearchComponent,
    UserSelectDropdownComponent,
    UserSelectModalComponent,
    ViewInvoiceDialogComponent,
    ViewKeyControlsDialogComponent,
    ViewMeetingDialogComponent,
    ViewQuoteDialogComponent,
    ViewTaskDialogComponent,
    WorkOrderChecklistComponent,
    WorkOrderDialogComponent,
    WorkOrderDetailsPanelComponent,
    WorkOrderHeaderComponent,
    WorkOrderTopicComponent,
    WorkOrderUpdateDialogComponent,
    WorkspaceDropdownComponent,
    WorkspaceSelectorComponent,
    YesNoSelectionComponent,

    // directives
    AutofocusDirective,
    UniqueEmailDirective,
    TextCollapseExpandDirective,

    // pipes
    AgendaFilterPipe,
    ArfInvoicesPipe,
    AsFormArrayPipe,
    AsFormGroupPipe,
    ArfStatusCountPipe,
    GetArfProductsTotalPricePipe,
    DecimalPipe,
    DirectoryUsersFilterPipe,
    DispatchFilterPipe,
    DispatchSortPipe,
    FilenameFilterPipe,
    FilterBySelectedTagsPipe,
    FilterCostCodeFormArrayBySearchTermPipe,
    FilterCostCodeBySearchTermPipe,
    FilterCostCodesByBudgetIsEnabledPipe,
    FilterCostCodeFormArrayByModuleIdPipe,
    FilterSubCostCodeFormArrayByModuleIdPipe,
    FilterPipe,
    FormatMeetingTimePipe,
    GetArfProductSubCostCodesPipe,
    HighlightPipe,
    HtmlEncodeDecodePipe,
    InvoiceFilterPipe,
    InvoiceSortPipe,
    IsEnabledPipe,
    IsJsonEquivalentPipe,
    LogPipe,
    MeetingDateFilterPipe,
    MeetingListFilterPipe,
    MessageFilterPipe,
    MilestonesFilterPipe,
    OrderByFieldPipe,
    OrdinalPipe,
    PadIdPipe,
    PaginatorPipe,
    PhoneNumberPipe,
    ProductFilterPipe,
    ProjectInvoiceTotalsPipe,
    ProjectListFilterPipe,
    ProjectUsersFilterPipe,
    PurchasingInvoiceListStatusCountPipe,
    RequestListFilterPipe,
    ReversePipe,
    SafeHtmlPipe,
    StringShrinkPipe,
    StripHtmlPipe,
    SubmittalsFilterPipe,
    TaskActivityFilterPipe,
    TaskListsPipe,
    TasksFilterPipe,
    TimeLogFilterPipe,
    TruncatePipe,
    USDollarPipe,
    UserListPipe,
    UserNameFilterPipe,
    UserSearchFilterPipe,
    WorkOrderListFilterPipe,
  ],
  imports: [
    AngularSplitModule,
    CKEditorModule,
    CurrencyMaskModule,
    CommonModule,
    DragDropModule,
    FormsModule,
    GoogleChartsModule,
    MaterialModule,
    PDFExportModule,
    ReactiveFormsModule,
    RouterModule,
    ScrollingModule,
    NgxDropzoneModule,
    MatSortModule,
    CdkTableModule,
  ],
  exports: [
    // modules
    AngularSplitModule,
    CKEditorModule,
    CurrencyMaskModule,
    CommonModule,
    DragDropModule,
    FormsModule,
    GoogleChartsModule,
    MaterialModule,
    PDFExportModule,
    ReactiveFormsModule,
    RouterModule,
    ScrollingModule,

    // pages
    // TODO remove this when we can do the other todo in this file
    ArfComponent,
    ArfListComponent,
    FilesComponent,
    InvoicesComponent,
    TasksComponent,
    PurchasingInvoiceListComponent,
    PurchasingBudgetListComponent,
    ProjectDirectoryComponent,
    ProjectBudgetComponent,
    ProjectBudgetCbComponent,

    // components
    AccountReviewDialogComponent,
    AddFollowersComponent,
    AddSubmittalsDialogComponent,
    AgendaItemDialogComponent,
    AidetComponent,
    ArfExportComponent,
    ArfProductComponent,
    ArfPurchaseTypeDialogComponent,
    ArfReviewDialogComponent,
    ARManagerDialogComponent,
    AssignedButtonComponent,
    AssignUserButtonComponent,
    AssignUserDialogComponent,
    AutoCompleteComponent,
    BarChartComponent,
    BaseDialogComponent,
    BidContractExportComponent,
    BidPackageInvoiceMenuComponent,
    BudgetReportExportComponent,
    BugReportDialogComponent,
    BuildingComponent,
    BuildingManagerComponent,
    BuildingManagerDialogComponent,
    CapxPanelComponent,
    CompanyProfileDocumentsDialogComponent,
    CheckboxContainerComponent,
    CompanyDialogComponent,
    ConcludeMeetingDialogComponent,
    ConfirmationChoiceDialogComponent,
    ConfirmationDialogComponent,
    CostCodeHelpDialogComponent,
    CostCodeSelectionComponent,
    CostCodeSelectionFormComponent,
    CoverLetterDialogComponent,
    CqiDialogComponent,
    CreateTaskDialogComponent,
    CustomerDialogComponent,
    DatepickerHeaderComponent,
    DepartmentComponent,
    DepartmentComponent,
    DepartmentManagerComponent,
    DepartmentManagerDialogComponent,
    DepartmentManagerDialogSuiteComponent,
    DispatchActivitiesComponent,
    DispatchRequestComponent,
    DispatchTopicComponent,
    DonutChartComponent,
    EditNoteDialogComponent,
    EditStaffDialogComponent,
    EditorComponent,
    FileApprovalReviewDialogComponent,
    FileAttachmentDialogComponent,
    FileChipComponent,
    FileChipListComponent,
    FilePreviewComponent,
    FilePreviewDialogComponent,
    FileRenameDialogComponent,
    FileSelectApprovalReviewDialogComponent,
    FileSelectModalComponent,
    FileThumbnailComponent,
    FileUploadZoneComponent,
    FilesUploadModalComponent,
    FloorComponent,
    FormComponent,
    ConstructionAccessComponent,
    HowToGuideDialogComponent,
    InviteUserDialogComponent,
    InvoiceAccessoryDataComponent,
    InvoiceCoverLetterComponent,
    InvoiceDataComponent,
    InvoiceMenuComponent,
    InvoiceReviewButtonsComponent,
    KeyControlIconBadgeComponent,
    LatestUpdateComponent,
    LocationComponent,
    LoginDialogComponent,
    MeetingDialogComponent,
    MeetingExportDialogComponent,
    MeetingSelectDialogComponent,
    MessagingConversationPanelComponent,
    MessagingConversationPanelMessageComponent,
    MessagingCreateConversationPanelComponent,
    MessagingInboxConversationBoxComponent,
    MessagingInboxDisplayComponent,
    MessagingInboxMessageBoxComponent,
    MessagingOverlayPanelComponent,
    MilestoneDialogComponent,
    MilestoneTaskContainerComponent,
    NewAccountModalComponent,
    NewConversationMessageComponent,
    NewGuestAccountModalComponent,
    NewInvoiceDialogComponent,
    NewTaskViewComponent,
    NotificationDialogComponent,
    NotificationOverlayPanelComponent,
    OpenTaskButtonComponent,
    ProfileInfoComponent,
    ProfileThumbnailComponent,
    ProgressIndicatorComponent,
    ProjectFollowersComponent,
    ProjectHeaderComponent,
    ProjectNavComponent,
    ProjectProductComponent,
    ProjectProductDialogComponent,
    ProjectTaskPanelComponent,
    ProjectTopicComponent,
    ProjectUpdateDialogComponent,
    QuoteDialogComponent,
    ReminderDialogComponent,
    RequestAccountDialogComponent,
    RequestCategoryComponent,
    RequestMethodComponent,
    RequestReviewDialogComponent,
    RequestTopicComponent,
    ResourceSearchComponent,
    ReviewProgressComponent,
    ReviewSidebarComponent,
    SearchOverlayPanelComponent,
    SearchUserInputComponent,
    SelectUserFromListDialogComponent,
    SidenavComponent,
    SplitButtonComponent,
    StringListPillContainerComponent,
    SubmittalButtonsComponent,
    SubmittalTransmittalComponent,
    SuiteComponent,
    SuitesManagerComponent,
    SuitesManagerDialogComponent,
    TaskAccessoryComponent,
    TaskReviewDialogComponent,
    TaskStatusIconComponent,
    TaskViewActivityComponent,
    TaskViewComponent,
    TitleSeventyFourKeyControlColumnsComponent,
    TitleSixyOneKeyControlColumnsComponent,
    TimeLogDialogComponent,
    TopicDialogComponent,
    TopicGroupComponent,
    TransferWorkOrderDialogComponent,
    TroubleshootLoginDialogComponent,
    UpdateAvailableComponent,
    UploadSubmittalsDialogComponent,
    UserProfilePreviewComponent,
    UserSearchComponent,
    UserSelectDropdownComponent,
    UserSelectModalComponent,
    ViewInvoiceDialogComponent,
    ViewKeyControlsDialogComponent,
    ViewMeetingDialogComponent,
    ViewQuoteDialogComponent,
    ViewTaskDialogComponent,
    WorkOrderChecklistComponent,
    WorkOrderDialogComponent,
    WorkOrderHeaderComponent,
    WorkOrderTopicComponent,
    WorkOrderUpdateDialogComponent,
    WorkspaceDropdownComponent,
    WorkspaceSelectorComponent,
    YesNoSelectionComponent,

    // directives
    AutofocusDirective,
    UniqueEmailDirective,
    TextCollapseExpandDirective,

    // pipes
    AgendaFilterPipe,
    ArfInvoicesPipe,
    AsFormArrayPipe,
    AsFormGroupPipe,
    ArfStatusCountPipe,
    GetArfProductsTotalPricePipe,
    DecimalPipe,
    DirectoryUsersFilterPipe,
    DispatchFilterPipe,
    DispatchSortPipe,
    FilenameFilterPipe,
    FilterBySelectedTagsPipe,
    FilterCostCodeFormArrayBySearchTermPipe,
    FilterCostCodeBySearchTermPipe,
    FilterCostCodesByBudgetIsEnabledPipe,
    FilterCostCodeFormArrayByModuleIdPipe,
    FilterSubCostCodeFormArrayByModuleIdPipe,
    FilterPipe,
    FormatMeetingTimePipe,
    GetArfProductSubCostCodesPipe,
    HighlightPipe,
    HtmlEncodeDecodePipe,
    InvoiceFilterPipe,
    InvoiceSortPipe,
    IsEnabledPipe,
    IsJsonEquivalentPipe,
    LogPipe,
    MeetingDateFilterPipe,
    MeetingListFilterPipe,
    MessageFilterPipe,
    MilestonesFilterPipe,
    OrderByFieldPipe,
    OrdinalPipe,
    PadIdPipe,
    PaginatorPipe,
    PhoneNumberPipe,
    ProductFilterPipe,
    ProjectInvoiceTotalsPipe,
    ProjectListFilterPipe,
    PurchasingInvoiceListStatusCountPipe,
    RequestListFilterPipe,
    ReversePipe,
    SafeHtmlPipe,
    StringShrinkPipe,
    StripHtmlPipe,
    SubmittalsFilterPipe,
    TaskActivityFilterPipe,
    TaskListsPipe,
    TasksFilterPipe,
    TimeLogFilterPipe,
    TimeLogPanelComponent,
    TimerComponent,
    TruncatePipe,
    USDollarPipe,
    UserListPipe,
    UserNameFilterPipe,
    UserSearchFilterPipe,
    WorkOrderListFilterPipe,
    MeetingAgendaItemNoteComponent,
  ],
  providers: [OrderByFieldPipe, PaginatorPipe, ProjectListFilterPipe, StripHtmlPipe],
})
export class SharedModule {}
