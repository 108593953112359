<div
  #mainScreen
  id="arf"
  data-test-id="arf"
  class="p-4 arf container"
  [ngClass]="{
    'open-dialog': isDialogOpen,
    'open-sidenav': !isSidenavClosed,
    'closed-sidenav': isSidenavClosed
  }"
>
  <div class="d-flex full-width align-items-start mt-1 border-bottom border-gray mb-4">
    <div class="d-flex flex-column mr-auto">
      <span class="d-flex align-items-center">
        <h3 class="dkblue mr-3">Acquisition Request Form</h3>
        <h5
          class="lh-30 br-15 px-3"
          [ngClass]="{
            'bg-shade-ltblue accent': arf?.status_id == 1,
            'bg-shade-orange dkorange': arf?.status_id == 2,
            'bg-shade-green dkgreen': arf?.status_id == 3,
            'bg-shade-accent primary': arf?.status_id == 4,
            'bg-gray white': arf?.status_id == 5
          }"
        >
          {{ arf?.arf_status?.name }}
        </h5>
      </span>
      <p class="dkgray">
        <a href="/purchasing" class="dkgray">Purchasing</a>
        <span class="px-2 dkgray"> / </span>
        <a href="/purchasing/arfs" class="dkgray">ARFs</a>
        <span class="px-2 dkgray"> / </span>
        <span class="gray">ARF {{ arf?.id }} </span>
      </p>
    </div>
    <div class="d-flex flex-column align-items-end flex-wrap ml-auto">
      <div class="d-flex align-items-center flex-wrap">
        <button [matMenuTriggerFor]="save_menu" mat-button class="{{ isUpdating ? 'green' : 'gray' }}">
          <mat-icon class="mr-2">{{ isUpdating ? 'published_with_changes' : 'check_circle' }}</mat-icon>
          <span>
            {{ isUpdating ? 'Saving...' : 'Saved' }}
          </span>
        </button>
        <mat-menu #save_menu="matMenu" xPosition="before">
          <div class="p-4 d-flex flex-column">
            <h6 class="primary">Latest version has been saved</h6>
            <p class="dkgray mb-0">
              Auto-save functionality is enabled and will automatically save when changes are made.
            </p>
          </div>
        </mat-menu>
        <button mat-icon-button [matMenuTriggerFor]="lock_menu" *ngIf="!canEdit" class="accent">
          <mat-icon>lock</mat-icon>
        </button>
        <mat-menu #lock_menu="matMenu" xPosition="before">
          <div class="p-4 d-flex flex-column">
            <h6 class="primary">This ARF is currently locked</h6>
            <p class="dkgray mb-0">
              This ARF is locked because is currently in review, approved, or finalized. To make a change, click the
              Make A Change button to the right.
            </p>
            <span>
              <button
                mat-flat-button
                color="warn"
                class="mt-3"
                *ngIf="authService.isAppAdmin || authService.isFinanceManager"
                (click)="setAdminEditing()"
              >
                Edit as Admin
              </button>
            </span>
          </div>
        </mat-menu>

        <button
          mat-stroked-button
          color="success"
          class="px-3 bg-white ml-3"
          *ngIf="arfIsClosed"
          (click)="reopenArf()"
          [disabled]="!isWorkspaceStaff"
        >
          Reopen ARF
        </button>

        <button
          mat-stroked-button
          class="px-3 bg-white dkgray ml-3"
          *ngIf="isEditingAsAdmin"
          (click)="cancelSaveAsAdmin()"
        >
          Discard Changes
        </button>
        <button mat-flat-button color="primary" class="px-3 ml-3" *ngIf="isEditingAsAdmin" (click)="saveAsAdmin()">
          <span class="pulse-dot-success"></span>
          Save as Admin
        </button>
        <mat-menu #more_menu="matMenu" xPosition="before">
          <button mat-menu-item>
            <mat-icon>content_copy</mat-icon>
            <span>Duplicate ARF</span>
          </button>
          <button mat-menu-item>
            <mat-icon>download</mat-icon>
            <span>Download PDF</span>
          </button>
        </mat-menu>

        <span *ngIf="isDownloadablePDF && isFileReady">
          <button mat-stroked-button color="accent" class="px-3 ml-3 bg-white" (click)="DownloadPDF()">
            Download PDF
          </button>
          <app-file-chip-list
            #fileChipListComponent
            style="display: none"
            [files]="noteFiles"
            [diameter]="40"
          ></app-file-chip-list>
        </span>

        <button
          mat-stroked-button
          color="primary"
          class="bg-white ml-3"
          [disabled]="!noPendingInvoices"
          (click)="closeArf()"
          *ngIf="!!arf && !arfIsClosed"
        >
          close ARF
        </button>
        <button
          mat-stroked-button
          color="warn"
          class="px-3 bg-white ml-3"
          *ngIf="!!arf && !canEdit && !arfIsClosed"
          (click)="resetReview()"
          [disabled]="!isWorkspaceStaff"
        >
          Make A Change
        </button>
        <button
          mat-flat-button
          color="success"
          class="ml-3"
          [ngClass]="{
            'bg-dkgreen': isReadyToReview,
            'bg-gray': !isReadyToReview
          }"
          *ngIf="!arf?.review_task_id"
          (click)="startARFTaskReview()"
        >
          <span *ngIf="isReadyToReview" class="pulse-dot-success"></span>
          Submit For Review
          <mat-icon class="ml-2">east</mat-icon>
        </button>
        <button
          mat-flat-button
          color="success"
          class="ml-3"
          [ngClass]="{
            'bg-dkgreen': isReadyToReview,
            'bg-gray': !isReadyToReview
          }"
          *ngIf="arf?.review_task_id && arf?.status_id !== ArfStatus.InReview"
          (click)="resubmitReview()"
        >
          <span *ngIf="isReadyToReview && arf?.company?.verification_status !== 1" class="pulse-dot-success"></span>
          Submit For Review
          <mat-icon class="ml-2">east</mat-icon>
        </button>
        <button
          mat-flat-button
          color="primary"
          class="ml-3 px-3"
          *ngIf="arfReviewIsComplete && ![ArfStatus.Finalized, ArfStatus.Closed].includes(arf?.status_id)"
          [disabled]="!arfReviewIsComplete"
          (click)="finalizeArf()"
        >
          <span
            *ngIf="arfReviewIsComplete && ![ArfStatus.Finalized, ArfStatus.Closed].includes(arf?.status_id)"
            class="pulse-dot-success"
          ></span>
          Finalize Purchase
        </button>
      </div>
      <div *ngIf="arf?.company?.verification_status === 1" class="mt-2 ml-auto">
        <h6 class="m-0 red">Cannot Submit: Supplier company is pending verification</h6>
      </div>
      <div *ngIf="!initialArfProducts?.length" class="mt-2 ml-auto">
        <h6 class="m-0 red">Cannot Submit: Missing a product</h6>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-8">
      <div class="m-portlet w-100 p-0 mb-4">
        <div class="m-portlet__body p-4 d-flex align-items-center bg-shade-ltblue border-bottom border-gray">
          <h4 class="text-wrap dkblue font-weight-semibold pointer mb-0">ARF Information</h4>
          <span *ngIf="arf?.created_by.id" class="d-flex flex-column text-right ml-auto">
            <p class="mb-0 dkgray">
              Created by
              <span (click)="openUserProfilePreview(arf?.created_by?.id)" class="accent hover-u pointer"
                >{{ arf?.created_by?.first_name }} {{ arf?.created_by?.last_name }}</span
              >
              • {{ arf?.created_datetime | date: 'EEE, MMM d YYYY h:mm aa' }}
            </p>
          </span>
        </div>
        <div class="m-portlet__body p-4 d-flex">
          <form [formGroup]="arfFormGroup" class="full-width">
            <div class="d-flex flex-column full-width">
              <div class="row mb-4">
                <div class="col-md-7">
                  <h6 class="m-0 dkblue font-weight-semibold mb-2">Short Description*</h6>
                  <mat-form-field class="full-width mb-4" appearance="outline">
                    <input placeholder="Title" matInput required="true" type="text" formControlName="title" />
                    <mat-error *ngIf="title.errors?.required">Title is required.</mat-error>
                  </mat-form-field>
                  <h6 class="m-0 dkblue font-weight-semibold mb-2">Supplier Company*</h6>
                  <mat-form-field appearance="outline" class="full-width mb-4">
                    <input
                      #company_auto_input
                      type="text"
                      placeholder="Select a Supplier Company"
                      matInput
                      [matAutocomplete]="company_auto"
                      [value]="arf?.company?.name"
                      (input)="filterCompanies($event)"
                      formControlName="company_id"
                    />
                    <mat-hint
                      class="d-flex align-items-center red"
                      *ngIf="arf?.company?.verification_status === 1 && !company_id?.pristine"
                    >
                      <i class="fa fa-exclamation-circle red mr-2" style="font-size: 1em"></i>
                      <span> Supplier is pending verification </span>
                    </mat-hint>
                    <mat-autocomplete autoActiveFirstOption #company_auto="matAutocomplete">
                      <mat-option
                        *ngFor="let c of filteredCompanies || []"
                        [value]="c.name"
                        [disabled]="!isCompanyAllowed(c)"
                        (click)="updateCompany(c)"
                      >
                        <p class="m-0 d-flex align-items-center">
                          <span class="mr-auto">
                            {{ c.name }}
                          </span>
                          <small class="ml-3">{{ CompanyVerificationStatus[c.verification_status] }}</small>
                        </p>
                      </mat-option>
                      <mat-option class="ltblue" (click)="createCompany()" [value]="companyInputName">
                        <i class="la la-plus"></i> &nbsp; New Supplier Company
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="company_id.invalid">
                      <span *ngIf="company_id.errors?.required">Supplier Company is required.</span>
                      <span *ngIf="company_id.errors?.invalidAutoCompleteOption">
                        Please select company from the list.
                      </span>
                    </mat-error>
                  </mat-form-field>
                  <div class="d-flex align-items-center">
                    <div class="d-flex flex-column mr-auto">
                      <h6 class="dkblue font-weight-semibold">
                        Tag Asset
                        <small [matMenuTriggerFor]="asset_menu">
                          <i class="fa fa-question-circle gray pointer"></i>
                        </small>
                        <mat-menu #asset_menu="matMenu" xPosition="after">
                          <div class="p-4 d-flex flex-column">
                            <h6 class="primary">Tag Asset</h6>
                            <p class="dkgray mb-0">
                              If a single, tangible asset is over $2,500, it will need to be tagged and cataloged by the
                              Inventory Control Officer (ICO). For telecommunications and electronic technology
                              applications specified in Title 62 -- these will need to be tagged and catologed if more
                              than $500.
                            </p>
                          </div>
                        </mat-menu>
                      </h6>
                      <p class="m-0 dkgray">Include task to tag asset upon delivery</p>
                    </div>
                    <mat-slide-toggle
                      class="example-margin"
                      color="accent"
                      [checked]="!!arf?.asset_tag"
                      (change)="updateTagAsset()"
                      [disabled]="!canEdit"
                    >
                    </mat-slide-toggle>
                  </div>
                </div>
                <div class="col-md-5">
                  <div>
                    <h6 class="primary">Workspace*</h6>
                    <mat-form-field appearance="outline" class="full-width mb-4">
                      <mat-select placeholder="Select Workspace" formControlName="module_id">
                        <mat-option [value]="w.id" *ngFor="let w of userStaffWorkspaces">{{ w.name }}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="module_id.errors?.required">Workspace is required.</mat-error>
                    </mat-form-field>
                    <h6 class="m-0 dkblue font-weight-semibold mb-2">Purchase Type*</h6>
                    <mat-form-field appearance="outline" class="full-width mb-4">
                      <mat-select placeholder="Select Purchase Type" formControlName="purchase_type_id">
                        <mat-option *ngFor="let pt of purchaseTypes" [value]="pt.id"> {{ pt.name }} </mat-option>
                      </mat-select>
                      <mat-error *ngIf="purchase_type_id.errors?.required">Purchase Type is required.</mat-error>
                    </mat-form-field>
                    <h6 class="m-0 dkblue font-weight-semibold mb-2">Fiscal Year*</h6>
                    <mat-form-field appearance="outline" class="full-width mb-0">
                      <mat-select placeholder="Select Fiscal Year" formControlName="fiscal_year">
                        <mat-option *ngFor="let year of fiscalYears" [value]="year.value">
                          {{ year.value }}<ng-container *ngIf="year.isCurrentYear">&nbsp;(Current)</ng-container>
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="fiscal_year.errors?.required">Fiscal Year is required.</mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="mb-2">
                <h6 class="m-0 dkblue">Summary*</h6>
                <p class="m-0 dkgray">Please be as detailed as possible</p>
              </div>
              <app-editor
                #editor
                placeholder="Summarize this purchase with the what and why"
                required_content="ARF Description is Required"
                required="true"
              ></app-editor>
            </div>
          </form>
        </div>
      </div>

      <div class="m-portlet p-0 mb-4">
        <div class="m-portlet__body p-4 d-flex align-items-center bg-shade-ltblue border-bottom border-gray">
          <h4 class="text-wrap dkblue font-weight-semibold pointer mb-0 mr-auto">Products</h4>
          <button mat-button (click)="openCostCodeHelpDialog()" color="accent" class="pl-2 lh-30 ml-3">
            <mat-icon>help</mat-icon>
            Cost Code Help
          </button>
          <button (click)="openProductListDialog()" mat-stroked-button color="accent" class="bg-white px-3 lh-30 ml-3">
            Manage Products
          </button>
          <button
            mat-flat-button
            color="success"
            class="px-3 lh-30 ml-3"
            *ngIf="isWorkspaceStaff"
            (click)="addProduct()"
            [disabled]="!canEdit || isEditingAsAdmin"
          >
            + Line Item
          </button>
        </div>
        <div class="m-portlet__body d-flex flex-column p-0">
          <div *ngIf="initialArfProducts?.length" class="d-flex flex-column full-width">
            <div class="row m-0 py-3 bg-shade-ltblue border-bottom border-gray">
              <div class="col-6 pl-4 pr-0">
                <h6 class="primary mb-0">Product</h6>
              </div>
              <div class="col-auto pr-0 text-right" style="width: 70px">
                <h6 class="primary mb-0 mr-2">Quantity</h6>
              </div>
              <div class="col pr-0 text-right">
                <h6 class="primary mb-0 mr-2">Price</h6>
              </div>
              <div class="col text-right">
                <h6 class="primary mb-0 mr-2">Total</h6>
              </div>
            </div>
            <div *ngIf="costCodes?.length && arf.module_id">
              <app-arf-product
                [ngClass]="{ 'border-bottom border-gray': !last }"
                *ngFor="let product of initialArfProducts; let last = last"
                #productComponent
                [costCodes]="costCodes"
                [fiscalYear]="arf?.fiscal_year"
                [products]="allProducts"
                [product]="product"
                [canEdit]="canEdit"
                [adminEditingStatus]="adminEditingStatus"
                [moduleId]="arf?.module_id"
                (productUpdating)="updateProduct($event)"
                (productIsDoneUpdating)="setIsUpdatingToFalse()"
                (productIsUpdated)="updateProduct($event)"
                (productIsDeleted)="deleteProduct($event)"
                (updateProducts)="updateProducts($event)"
              >
              </app-arf-product>
              <div class="d-flex flex-column align-items-center p-5 mx-auto" *ngIf="arfProductIsLoading">
                <mat-spinner color="accent" [diameter]="40"></mat-spinner>
              </div>
            </div>
          </div>
        </div>
        <div class="m-portlet__body p-0 d-flex align-items-stretch border-top border-gray">
          <div class="d-flex flex-column flex-fill p-4 border-right border-gray">
            <mat-chip-list class="d-inline-block align-middle">
              <button
                mat-flat-button
                color="primary"
                class="pl-2 pr-3"
                *ngIf="!arf?.quote_file"
                (click)="openUploadModal()"
                [disabled]="!isWorkspaceStaff"
              >
                <mat-icon>attach_file</mat-icon>
                <span class="ml-1"> Attach Quote </span>
              </button>
              <app-file-chip
                *ngIf="arf?.quote_file"
                [file]="arf?.quote_file"
                [removable]="canEdit"
                (removeEvent)="removeFile(arf?.quote_file)"
              ></app-file-chip>
            </mat-chip-list>
            <p *ngIf="!arf?.quote_file" class="red mb-0 mt-2">Required. Must be PDF, JPG, or PNG.</p>
            <p *ngIf="arf?.quote_file" class="green mb-0 mt-2">Valid File Type</p>
          </div>
          <div class="d-flex flex-fill flex-column p-4 bg-shade-ltblue text-right">
            <h6 class="primary">Total Cost</h6>
            <h2 *ngIf="!arfIsClosed" class="primary mb-0">{{ arfTotal | USDollar }}</h2>
            <h2 *ngIf="arfIsClosed && arfTotalEqualsInvoicesTotal" class="primary mb-0">{{ arfTotal | USDollar }}</h2>
            <span *ngIf="arfIsClosed && !arfTotalEqualsInvoicesTotal">
              <h5
                class="primary"
                [ngClass]="{ strike: arfIsClosed && !arfTotalEqualsInvoicesTotal }"
                *ngIf="arfIsClosed && !arfTotalEqualsInvoicesTotal"
              >
                {{ arfTotal | USDollar: 2:true }}
              </h5>
              <h2 class="primary mb-0">{{ invoicesTotal | USDollar: 2:true }}</h2>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 overflow-auto">
      <section class="mb-4">
        <div class="m-portlet m-0">
          <div class="m-portlet__body bg-shade-ltblue d-flex align-items-center p-3">
            <h4 class="m-0 dkblue font-weight-semibold lh-34">Actions</h4>
            <span class="ml-auto"> </span>
          </div>
          <div class="m-portlet__body d-flex align-items-center border-top border-gray align-items-center p-3">
            <h5 class="primary mb-0 mr-auto pointer" (click)="openEditStaffDialog(true)">Review Chain</h5>
            <button
              mat-flat-button
              *ngIf="!arf?.custody_chain_id"
              color="success"
              class="ml-auto lh-28 px-3"
              (click)="openEditStaffDialog()"
              [disabled]="!canEdit"
            >
              <span class="pulse-dot-success"></span>
              Set
            </button>
            <button
              mat-stroked-button
              *ngIf="arf?.custody_chain_id"
              color="accent"
              class="ml-auto lh-28 px-3"
              (click)="openEditStaffDialog()"
              [disabled]="!canEdit"
            >
              Edit
            </button>
          </div>
          <div class="m-portlet__body d-flex align-items-center border-top border-gray align-items-center p-3 pointer">
            <h5 class="primary mb-0 mr-auto" (click)="viewTask(arf?.review_task_id)">ARF Review</h5>
            <small *ngIf="!arf?.review_task_id" class="gray"> Submit for Review to create task </small>
            <app-open-task-button *ngIf="arf?.review_task_id" [taskId]="arf.review_task_id"> </app-open-task-button>
          </div>
          <div
            *ngIf="
              arf?.purchase_type_id === ArfPurchaseTypeId.PurchaseOrder ||
              arf?.purchase_type_id === ArfPurchaseTypeId.CreditCard
            "
            class="m-portlet__body d-flex align-items-center border-top border-gray align-items-center p-3"
          >
            <h5 class="primary mb-0 mr-auto">
              {{ (!arf?.purchase_type_id && 'CC or PO Purchase') || '' }}
              {{ (arf?.purchase_type_id === ArfPurchaseTypeId.PurchaseOrder && 'PO Task') || '' }}
              {{ (arf?.purchase_type_id === ArfPurchaseTypeId.CreditCard && 'CC Task') || '' }}
            </h5>
            <h6 *ngIf="arf?.purchase_type_id === ArfPurchaseTypeId.Vendor">N/A</h6>
            <small class="gray" *ngIf="arf?.purchase_type_id !== ArfPurchaseTypeId.Vendor && !arf?.purchase_task_id">
              Task will create on ARF finalization
            </small>
            <app-open-task-button *ngIf="arf?.purchase_task_id" [taskId]="arf.purchase_task_id"> </app-open-task-button>
          </div>
          <div
            *ngIf="arf?.asset_tag"
            class="m-portlet__body d-flex align-items-center border-top border-gray align-items-center p-3"
          >
            <h5 class="primary mb-0 mr-auto">Tag Asset</h5>
            <h6 *ngIf="!arf?.asset_tag">N/A</h6>
            <small class="gray" *ngIf="arf?.asset_tag && !arf?.tag_asset_task_id">
              Task will create on ARF finalization
            </small>
            <app-open-task-button *ngIf="arf?.tag_asset_task_id" [taskId]="arf.tag_asset_task_id">
            </app-open-task-button>
          </div>
        </div>
      </section>
      <section *ngIf="isWorkspaceStaff" class="mb-4">
        <div class="m-portlet m-0">
          <div class="m-portlet__body bg-shade-ltblue d-flex align-items-center p-3">
            <h4 class="m-0 dkblue font-weight-semibold">Invoices</h4>
            <mat-icon [matMenuTriggerFor]="menu" class="m-2 pointer">sort</mat-icon>
            <mat-menu #menu="matMenu">
              <button *ngFor="let so of sortOptions" mat-menu-item (click)="changeSort($event, so.value)">
                <span>{{ so.name }}</span>
                <mat-icon class="ltblue ml-2" *ngIf="fieldToSortBy === so.value && sortDirection === 'asc'"
                  >arrow_upward</mat-icon
                >
                <mat-icon class="ltblue ml-2" *ngIf="fieldToSortBy === so.value && sortDirection === 'desc'"
                  >arrow_downward</mat-icon
                >
              </button>
            </mat-menu>
            <button
              mat-flat-button
              color="success"
              class="ml-auto lh-28 px-3"
              (click)="openNewInvoiceDialog()"
              [disabled]="!arfIsFinalized"
            >
              + Invoice
            </button>
          </div>
          <div
            *ngFor="
              let invoice of arf?.invoices || []
                | arfInvoices: fieldToSortBy:sortDirection
                | paginator: paginator.pageIndex:paginator.pageSize
            "
            class="m-portlet__body d-flex align-items-center border-top border-gray p-3"
          >
            <mat-icon
              *ngIf="[InvoiceStatus.New, InvoiceStatus.Received].includes(invoice.status_id)"
              class="mr-2 pointer gray"
            >
              pending
            </mat-icon>
            <mat-icon *ngIf="invoice.status_id === InvoiceStatus.Approved" class="mr-2 pointer gray">
              check_circle
            </mat-icon>
            <mat-icon
              *ngIf="invoice.status_id === InvoiceStatus.ReadyForPayment"
              class="mr-2 pointer green"
              color="success"
            >
              check_circle
            </mat-icon>
            <mat-icon *ngIf="invoice.status_id === InvoiceStatus.Rejected" class="mr-2 pointer red"> cancel </mat-icon>
            <div>
              <h5 class="primary mb-0 mr-auto ltblue pointer hover-u" (click)="openViewInvoiceDialog(invoice.id)">
                {{ invoice.number | truncate: 20 }}
              </h5>
              <div class="gray">{{ invoice.created_datetime | date: 'EEE, MMM d YYYY' }}</div>
            </div>
            <h5 class="primary mb-0 ml-auto">{{ invoice.total | USDollar }}</h5>
            <mat-progress-spinner
              matSuffix
              *ngIf="!invoice.approval_task_id"
              mode="indeterminate"
              diameter="20"
            ></mat-progress-spinner>
            <app-invoice-menu
              #invoiceMenuComponent
              *ngIf="invoice.approval_task_id"
              [invoice]="invoice"
              [arf]="arf"
              [display]="false"
              [displayInvoiceButton]="false"
              [disableReview]="true"
              (updateInvoice)="refresh()"
              (changeDialogOpenStatus)="changeDialogOpenStatus($event)"
            >
            </app-invoice-menu>
          </div>
          <div>
            <mat-paginator
              #paginator
              [length]="arf?.invoices?.length"
              pageSize="5"
              (page)="pageEvent = $event"
              aria-label="Select page"
              hidePageSize
            >
            </mat-paginator>
          </div>
          <div
            *ngIf="invoicesTotal > 0"
            class="m-portlet__body bg-shade-ltblue d-flex align-items-center p-3 border-top border-gray"
          >
            <h5 class="m-0 mr-auto dkblue font-weight-semibold">Total Billed</h5>
            <h5 class="m-0 ml-auto dkblue font-weight-semibold">{{ invoicesTotal | USDollar }}</h5>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
<app-arf-export #arfFile [arf]="arf"></app-arf-export>
