import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ceil, every, has, remove, round, sumBy, uniqBy } from 'lodash';
import * as moment from 'moment';
import { DatepickerHeaderComponent, FileAttachmentDialogComponent } from 'src/app/components';
import { BidPackageStatus, InvoiceType, ResourceType, UserType, Workspace } from 'src/app/enums';
import {
  ArfsService,
  AuthService,
  FileService,
  ModalService,
  ProductService,
  ProgressIndicatorService,
  ProjectService,
} from 'src/app/services';
import {
  APIFilter,
  Arf,
  ArfInvoiceAmount,
  ArfProduct,
  Invoice,
  ProjectProduct,
  Quote,
  SubCostCodeBudget,
  UhatFileReference,
} from 'src/app/types';
import { BidPackageDialogComponent } from 'src/app/workspaces/construction/components';
import { BidPackage } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-new-invoice-dialog',
  templateUrl: './new-invoice-dialog.component.html',
  styleUrls: ['./new-invoice-dialog.component.scss'],
})
export class NewInvoiceDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<NewInvoiceDialogComponent>,
    public arfService: ArfsService,
    public authService: AuthService,
    private projectService: ProjectService,
    private dialog: MatDialog,
    private progressIndicatorService: ProgressIndicatorService,
    private snackbar: MatSnackBar,
    private modalService: ModalService,
    private fileService: FileService,
    private productService: ProductService,
    private fb: FormBuilder
  ) {}

  @ViewChild('invoiceData') invoiceData;
  public customHeader = DatepickerHeaderComponent;

  invoiceFields = [
    'id',
    'number',
    'bid_package_id',
    'quote_id',
    'created_datetime',
    'invoice_date',
    'invoice_end_date',
    'change_order_id',
    'change_order_local_index',
    'change_order_short_description',
    'change_order_cost_change',
    'is_retainage',
    'title',
    'total',
    'retainage',
    'shipping',
    'tax',
    'status_id',
    'status_name',
    'files',
  ];
  bidPackageFields = [
    'id',
    'trade_id',
    'trade_allows_bids',
    'trade_allows_nonbid_invoices',
    'trade_name',
    'awarded_company_id',
    'awarded_company_name',
    'project_id',
    'project_code',
  ];
  public eInvoiceType = InvoiceType;
  changeOrderFields = ['id', 'status_id', 'code', 'cost_change', 'short_description', 'summary', 'local_index'];
  quoteFields = [
    'id',
    'code',
    'awarded_amount',
    'company{name}',
    'project{id,code}',
    'tenant{id,tenant_name},description',
  ];
  currentWorkspace: Workspace;
  allowedTradesWithoutBidPackages = [];
  awardedBidPackages = [];
  noBidPackages = [];
  awardedQuotes: Quote[] = [];
  changeOrders = [];
  currentUser;
  userCompany;
  existingFiles: UhatFileReference[] = [];
  newFiles: UhatFileReference[] = [];
  filesToUnlink: UhatFileReference[] = [];
  selectedBidPackage: BidPackage;
  selectedQuote: Quote;
  updateInvoice: boolean;
  arf: Arf;
  initialArfInvoiceAmounts: ArfInvoiceAmount[];
  arfInvoiceAmounts: ArfInvoiceAmount[] = [];
  arfInvoiceAmountIndex = 0;
  arfInvoiceAmountsToDelete: ArfInvoiceAmount[] = [];
  subCostCodeBudgets: SubCostCodeBudget[];
  allowAdditionalCodes: boolean;
  isLoading: boolean;

  // should be above the invoiceFormGroup since its used in the title field
  public title_length = 75;
  invoiceFormGroup: FormGroup = this.fb.group({
    invoice_number: [null, [Validators.required]],
    title: [null, [Validators.required, Validators.maxLength(this.title_length)]], // title
    bid_package_id: [null],
    quote_id: [null],
    arf_id: [null],
    change_order: [null, [Validators.required]],
    change_order_id: [null],
    is_retainage: [null, [Validators.required]],
    invoice_date: [null, [Validators.required]],
    invoice_end_date: [null],
    total: [null, [Validators.required]],
    retainage: [null],
    shipping: [null],
    tax: [null],
    is_single_date: [true],
  });

  get invoice_number() {
    return this.invoiceFormGroup.get('invoice_number');
  }
  get title() {
    return this.invoiceFormGroup.get('title');
  }
  get title_counter(): string {
    return `${this.title?.value?.length || 0} / ${this.title_length}`;
  }
  get bid_package_id() {
    return this.invoiceFormGroup.get('bid_package_id');
  }
  get quote_id() {
    return this.invoiceFormGroup.get('quote_id');
  }
  get arf_id() {
    return this.invoiceFormGroup.get('arf_id');
  }
  get change_order() {
    return this.invoiceFormGroup.get('change_order');
  }
  get is_retainage() {
    return this.invoiceFormGroup.get('is_retainage');
  }
  get invoice_date() {
    return this.invoiceFormGroup.get('invoice_date');
  }
  get invoice_end_date() {
    return this.invoiceFormGroup.get('invoice_end_date');
  }
  get total() {
    return this.invoiceFormGroup.get('total');
  }
  get retainage() {
    return this.invoiceFormGroup.get('retainage');
  }
  get shipping() {
    return this.invoiceFormGroup.get('shipping');
  }
  get tax() {
    return this.invoiceFormGroup.get('tax');
  }

  get is_single_date() {
    return this.invoiceFormGroup.get('is_single_date');
  }

  get currentInvoice() {
    return {
      id: this.data?.invoice?.id,
      status_id: this.data?.invoice?.status_id,
      title: this.title.value,
      invoice_date: moment(this.invoice_date.value).format('YYYY-MM-DD'),
      invoice_end_date: !this.is_single_date?.value ? moment(this.invoice_end_date.value).format('YYYY-MM-DD') : null,
      number: this.invoice_number.value ? this.invoice_number.value.toString() : null,
      total: +this.total.value || 0,
      retainage: +this.retainage.value || 0,
      shipping: +this.shipping.value || 0,
      tax: +this.tax.value || 0,
      is_retainage: this.is_retainage.value,
      change_order_id: this.change_order.value?.id || null,
      received_date: moment(this.data?.invoice?.received_date).format('YYYY-MM-DD') || this.today.format('YYYY-MM-DD'),
    };
  }

  get standardRetainage() {
    return ceil((this.total.value ?? 0) * 0.05, 2);
  }

  async ngOnInit(): Promise<void> {
    this.arf = this.data?.arf;
    this.updateInvoice = this.data?.updateInvoice;
    let invoice = this.data?.invoice;

    if (invoice?.id) {
      this.isLoading = true;
      const requiredInvoiceFields = [...this.invoiceFields];
      const missingFields = [];
      const hasAllFields = every(requiredInvoiceFields, (field) => {
        if (has(invoice, field)) {
          return true;
        } else {
          missingFields.push(field);
          return false;
        }
      });
      if (!hasAllFields) {
        console.warn(
          `New Invoice Dialog is missing the required fields: ${missingFields.join(', ')}! Getting them from the API.`
        );
        invoice = await this.projectService.getInvoiceById(invoice.id, this.invoiceFields).toPromise();
      }
      this.existingFiles =
        (invoice?.files?.length && invoice.files) ||
        (await this.fileService.getFilesByParentId(ResourceType.Invoice, invoice.id).toPromise());
      this.invoice_number.setValue(invoice.number);
      this.title.setValue(invoice.title);
      this.bid_package_id.setValue(invoice.bid_package_id);
      this.bid_package_id.disable();
      this.quote_id.setValue(invoice.quote_id);
      this.quote_id.disable();
      this.is_retainage.setValue(invoice.is_retainage);
      const changeOrder = {
        id: invoice.change_order_id,
        local_index: invoice.change_order_local_index,
        short_description: invoice.change_order_short_description,
        cost_change: invoice.change_order_cost_change,
      };
      this.change_order.setValue(changeOrder);
      this.invoice_date.setValue(invoice.invoice_date);
      this.invoice_end_date.setValue(invoice.invoice_end_date);
      this.total.setValue(this.roundDecimal(invoice.total, 2));
      this.retainage.setValue(this.roundDecimal(invoice.retainage, 2));
      this.shipping.setValue(this.roundDecimal(invoice.shipping, 2));
      this.tax.setValue(this.roundDecimal(invoice.tax, 2));
      this.is_single_date.setValue(!this.invoice_end_date?.value);
    }
    this.currentWorkspace = this.data?.currentWorkspace || Workspace.Construction;
    if (this.currentWorkspace === Workspace.Construction) {
      this.title.setValidators(null);
      this.quote_id.setValidators(null);
      this.quote_id.updateValueAndValidity();
      this.shipping.updateValueAndValidity();
      this.tax.updateValueAndValidity();
      this.title.updateValueAndValidity();

      if (!this.arf?.id) {
        this.bid_package_id.setValidators([Validators.required]);
        this.retainage.setValidators([Validators.required]);
        this.is_retainage.setValidators([Validators.required]);
        this.bid_package_id.updateValueAndValidity();
        this.is_retainage.updateValueAndValidity();
        this.retainage.updateValueAndValidity();
        await this.getBidPackagesAndTrades();
        await this.bidPackageChanged();
        this.allowAdditionalCodes = true;
      } else {
        this.arf_id.setValue(this.arf.id);
      }
    } else {
      this.title.setValidators([Validators.required, Validators.maxLength(this.title_length)]);
      this.bid_package_id.setValidators(null);
      this.retainage.setValidators(null);
      this.is_retainage.setValidators(null);
      this.bid_package_id.updateValueAndValidity();
      this.is_retainage.updateValueAndValidity();
      this.retainage.updateValueAndValidity();
      this.shipping.updateValueAndValidity();
      this.tax.updateValueAndValidity();

      if (!this.arf?.id) {
        this.quote_id.updateValueAndValidity();
      } else {
        this.arf_id.setValue(this.arf.id);
      }

      if (this.data?.invoice?.project_id || this.projectService.currentSelectedProjectId) {
        this.quote_id.setValidators([Validators.required]);
        const quoteFilters: APIFilter[] = [
          {
            type: 'field',
            field: 'project_id',
            value: this.data?.invoice?.project_id.toString() || this.projectService.currentSelectedProjectId.toString(),
          },
          { type: 'operator', value: 'AND' },
          {
            type: 'field',
            field: 'is_awarded',
            value: '1',
          },
        ];

        const quoteItems = await this.productService
          .getQuoteItems(
            [
              `quote{${this.quoteFields.join(
                ','
              )}},project_product{sub_cost_code_budget{label,code,cost_code{label,code}}}`,
            ],
            quoteFilters
          )
          .toPromise();

        const quotes = [];
        quoteItems.forEach((quoteItem) => {
          quotes.push({
            id: quoteItem.quote_id,
            company: quoteItem.quote?.company,
            project: quoteItem.quote?.project,
            awarded_amount: quoteItem.quote?.awarded_amount,
            code: quoteItem.quote?.code,
            tenant_name:
              this.currentWorkspace !== this.eWorkspace?.Construction
                ? quoteItem?.quote?.tenant?.tenant_name
                  ? quoteItem.quote.tenant.tenant_name
                  : 'UHAT'
                : null,
            description: quoteItem.quote.description,
          });
        });
        this.awardedQuotes = uniqBy(quotes, 'id');

        for (const q of this.awardedQuotes) {
          const childQuoteItems = quoteItems.filter((qi) => qi.quote_id === q.id);
          q.project_products = childQuoteItems.map((cqi) => cqi.project_product);
        }

        await this.quoteChanged();
      }
    }

    if (!this.changeOrders?.length) {
      this.change_order.setValue({ id: null });
    }

    this.isLoading = false;

    await this.getArfInvoiceAmountInformation(invoice?.id);

    this.arfService.arfInvoiceAmountUpdated.subscribe((arfInvoiceAmount) => {
      const arfInvoiceAmountIndex = this.arfInvoiceAmounts?.findIndex(
        (a) =>
          (arfInvoiceAmount?.id && a?.id === arfInvoiceAmount?.id) ||
          (!isNaN(arfInvoiceAmount?.index) && a?.index === arfInvoiceAmount?.index)
      );

      if (arfInvoiceAmountIndex > -1) {
        if (arfInvoiceAmount.id) {
          arfInvoiceAmount.update_required = true;
        }
        this.arfInvoiceAmounts[arfInvoiceAmountIndex] = {
          ...this.arfInvoiceAmounts[arfInvoiceAmountIndex],
          ...arfInvoiceAmount,
        };
      } else {
        this.arfInvoiceAmounts.push(arfInvoiceAmount);
        this.initialArfInvoiceAmounts = [...[], ...this.arfInvoiceAmounts];
      }
    });

    this.arfService.arfInvoiceAmountDeleted.subscribe((arfInvoiceAmount) => {
      const arfInvoiceAmountIndex = this.arfInvoiceAmounts?.findIndex(
        (a) =>
          (arfInvoiceAmount?.id && a?.id === arfInvoiceAmount?.id) ||
          (!isNaN(arfInvoiceAmount?.index) && a?.index === arfInvoiceAmount?.index)
      );

      this.arfInvoiceAmounts = this.arfInvoiceAmounts.filter(
        (a) => (a.id && a.id !== arfInvoiceAmount.id) || a.index !== arfInvoiceAmount.index
      );
      this.initialArfInvoiceAmounts = [...[], ...this.arfInvoiceAmounts];
      this.subCostCodeBudgets.splice(arfInvoiceAmountIndex, 1);

      if (arfInvoiceAmount?.id) {
        this.arfInvoiceAmountsToDelete.push(arfInvoiceAmount);
      }
    });
  }

  public get eWorkspace() {
    return Workspace;
  }

  get arfInvoiceTotal(): string {
    return (sumBy(this.arfInvoiceAmounts || [], 'amount') || 0).toFixed(2);
  }

  get selectedQuoteHasCostCodes(): boolean {
    return !!this.selectedQuote?.project_products?.find((p) => p?.sub_cost_code_budget_id);
  }

  get invoiceTotalDoesNotMatchArfTotal(): boolean {
    return Number((this.total.value - this.retainage.value || 0).toFixed(2)) !== Number(this.arfInvoiceTotal);
  }

  get subCostCodesSelected(): boolean {
    return !this.arfInvoiceAmounts?.length || !this.arfInvoiceAmounts.find((a) => !a.sub_cost_code_budget_id);
  }

  get isSupplier(): boolean {
    return this.authService.currentUser.user_type_id === UserType.Vendor;
  }

  async getBidPackagesAndTrades() {
    const allNonBidAllowedTrades =
      this.authService.isUserWorkspaceStaff(this.currentWorkspace) ||
      this.authService.isProjectEngineer(
        this.data?.invoice?.project_id.toString() || this.projectService.currentSelectedProjectId
      )
        ? await this.projectService
            .getTrades([{ type: 'field', field: 'allow_nonbid_invoices', value: '1' }])
            .toPromise()
        : [];
    const bidPackages = await this.projectService
      .getBidPackages(
        [
          {
            type: 'field',
            field: 'project_id',
            value: (this.data?.invoice?.project_id ?? this.projectService.currentSelectedProjectId).toString(),
          },
          { type: 'operator', value: 'AND' },
          { type: 'field', field: 'child_request_id', value: null },
          { type: 'operator', value: 'AND' },
          { type: 'field', field: 'child_project_id', value: null },
          { type: 'operator', value: 'AND' },
          { type: 'field', field: 'status_id', value: `${BidPackageStatus.OPEN}^null` },
        ],
        this.bidPackageFields
      )
      .toPromise();
    const bidPackageTradeIds = bidPackages.map((bp) => bp.trade_id);
    this.allowedTradesWithoutBidPackages = allNonBidAllowedTrades.filter(
      (t) => bidPackageTradeIds.indexOf(t.id) === -1
    );
    this.awardedBidPackages = bidPackages.filter((bp) => !!bp.awarded_company_id || bp.trade_allows_nonbid_invoices);
    this.noBidPackages = bidPackages.filter((bp) => +bp.trade_allows_bids === 0);
  }

  promptNewBidPackage(tradeId: number) {
    const isProjectAdmin = this.isProjectAdmin();
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Bid Package Required',
        descriptionText: `This trade requires a bid package to create an invoice but does not yet have one. ${
          isProjectAdmin
            ? 'Would you like to create it now?'
            : 'Please contact the Project Manager or Architect to create the bid package before an invoice can be uploaded.'
        }`,
        hideConfirmationButton: !isProjectAdmin,
        cancelButtonText: isProjectAdmin ? 'Cancel' : 'Dismiss',
      })
      .subscribe(async (isConfirmed) => {
        if (isConfirmed) {
          const dialogRef = this.dialog.open(BidPackageDialogComponent, {
            disableClose: true,
            data: {
              bidPackage: { trade_id: tradeId },
            },
          });

          dialogRef.afterClosed().subscribe(async (createdBidPackage) => {
            if (createdBidPackage) {
              await this.getBidPackagesAndTrades();
              this.bid_package_id.setValue(createdBidPackage.id);
              this.bidPackageChanged();
            }
          });
        }
      });
  }

  async bidPackageChanged() {
    if (this.bid_package_id.value) {
      const foundBidPackage = [...this.awardedBidPackages, ...this.noBidPackages].find(
        (bp) => bp.id === this.bid_package_id.value
      );
      if (foundBidPackage) {
        this.selectedBidPackage = foundBidPackage;
      } else {
        this.selectedBidPackage = null;
      }
    } else {
      this.selectedBidPackage = null;
    }
    await this.getChangeOrders();
  }

  async quoteChanged(getArfInvoiceAmounts = false): Promise<void> {
    this.selectedQuote = (this.awardedQuotes || []).find((q) => q.id === this.quote_id.value) || null;
    // await this.getChangeOrders(); // uncomment this when we add non construction COs

    if (getArfInvoiceAmounts) {
      await this.getArfInvoiceAmountInformation();
    }
  }

  async getArfInvoiceAmountInformation(invoiceId?: number) {
    invoiceId = invoiceId || this.data?.invoice?.id;
    if (
      invoiceId &&
      (this.arf?.id ||
        this.selectedQuoteHasCostCodes ||
        (!this.arf && this.currentWorkspace === Workspace.Construction)) &&
      !this.isSupplier
    ) {
      const arfFilter: APIFilter[] = [{ type: 'field', field: 'invoice_id', value: invoiceId, match: 'exact' }];
      this.arfInvoiceAmounts =
        (await this.arfService.getArfInvoiceAmounts(arfFilter, this.arfService.arfInvoiceAmountFields).toPromise()) ||
        [];
    }

    const products: ArfProduct[] | ProjectProduct[] = this.arf?.products || this.selectedQuote?.project_products || [];
    const arfInvoiceData = this.arfService.getArfInvoiceAmountsAndBudgets(products, this.arfInvoiceAmounts);
    if (
      !this.arf &&
      this.currentWorkspace === Workspace.Construction &&
      !arfInvoiceData.arfInvoiceAmounts?.length &&
      !this.isSupplier
    ) {
      arfInvoiceData.arfInvoiceAmounts.push({
        index: this.arfInvoiceAmounts.length,
        amount: null,
        sub_cost_code_budget_id: null,
      });

      arfInvoiceData.subCostCodeBudgets.push({});
    }

    const oldArfInvoiceAmounts = this.arfInvoiceAmounts.filter(
      (a) => !arfInvoiceData.arfInvoiceAmounts.find((ia) => ia.id === a.id)
    );
    this.subCostCodeBudgets = arfInvoiceData.subCostCodeBudgets;
    this.arfInvoiceAmounts = arfInvoiceData.arfInvoiceAmounts;
    this.initialArfInvoiceAmounts = [...[], ...this.arfInvoiceAmounts];

    for (const arfInvoiceAmount of oldArfInvoiceAmounts || []) {
      await this.arfService.deleteArfInvoiceAmount(arfInvoiceAmount?.id).toPromise();
    }
  }

  async getChangeOrders() {
    if (this.bid_package_id.value || this.quote_id.value) {
      const changeOrderFilters = [
        {
          type: 'field',
          field: 'project_id',
          value: this.data?.invoice?.project_id.toString() || this.projectService.currentSelectedProjectId.toString(),
        },
        { type: 'operator', value: 'AND' },
        { type: 'field', field: 'status_id', value: '2' },
        { type: 'operator', value: 'AND' },
      ];
      if (this.currentWorkspace === Workspace.Construction) {
        changeOrderFilters.push({
          type: 'field',
          field: 'bid_package_id',
          value: this.bid_package_id.value.toString(),
        });
      } else {
        changeOrderFilters.push({
          type: 'field',
          field: 'quote_id',
          value: this.quote_id.value.toString(),
        });
      }
      this.changeOrders = await this.projectService
        .getChangeOrders(changeOrderFilters, this.changeOrderFields)
        .toPromise();
      // exclude change orders with a negative cost_change
      this.changeOrders = this.changeOrders.filter((co) => co.cost_change >= 0);
    } else {
      this.changeOrders = [];
    }

    if (!this.change_order.value) {
      this.change_order.setValue(this.changeOrders.length ? null : { id: null });
    }
  }

  changeOrderCompare(val1, val2) {
    return val1?.id === val2?.id;
  }

  isRetainageChanged() {
    if (this.is_retainage?.value) {
      this.retainage.setValue(this.roundDecimal(0, 2));
      this.retainage.disable();
    } else {
      if (this.currentWorkspace === Workspace.Construction && !this.arf?.id) {
        this.retainage.setValue(this.standardRetainage.toFixed(2));
        this.retainage.enable();
      }
    }
  }

  get today() {
    return moment();
  }

  get retainagePercentage() {
    return +this.total.value ? (+this.retainage.value || 0) / +this.total.value : 0;
  }

  private roundDecimal(value, digits: number) {
    return round(value || 0, digits).toFixed(digits);
  }

  public totalBlur() {
    this.total.setValue(this.roundDecimal(this.total.value, 2));
    if (!this.is_retainage.value && this.currentWorkspace === Workspace.Construction && !this.arf?.id) {
      this.retainage.setValue(this.standardRetainage.toFixed(2));
    }

    this.setArfInvoiceAmount();
  }

  public setArfInvoiceAmount() {
    if (this.initialArfInvoiceAmounts?.length === 1) {
      this.initialArfInvoiceAmounts[0].amount = this.total.value - this.retainage.value || 0;
      this.initialArfInvoiceAmounts = [...[], ...this.initialArfInvoiceAmounts];
      this.initialArfInvoiceAmounts[0].sub_cost_code_budget_id = this.arfInvoiceAmounts?.[0]?.sub_cost_code_budget_id;
      this.arfInvoiceAmounts = this.initialArfInvoiceAmounts;
      this.arfService.arfInvoiceAmountValueChanged.emit(this.arfInvoiceAmounts[0]);
    }
  }

  public retainageBlur() {
    this.retainage.setValue(this.roundDecimal(this.retainage.value, 2));
    this.setArfInvoiceAmount();
  }

  public shippingBlur() {
    this.shipping.setValue(this.roundDecimal(this.shipping.value, 2));
  }

  public taxBlur() {
    this.tax.setValue(this.roundDecimal(this.tax.value, 2));
  }

  cancel(): void {
    this.dialogRef.close();
  }

  toggleInvoiceDateType() {
    this.is_single_date.setValue(!this.is_single_date?.value);
    if (this.is_single_date.value) {
      this.invoice_end_date.setValidators(null);
      this.invoice_end_date.updateValueAndValidity();
    } else {
      this.invoice_end_date.setValidators([Validators.required]);
      this.invoice_end_date.updateValueAndValidity();
    }
  }

  openUploadModal() {
    const parentResourceType = this.arf?.id ? ResourceType.AcquisitionRequestForm : ResourceType.Project;
    const parentResourceId =
      this.arf?.id || this.data?.invoice?.project_id.toString() || this.projectService.currentSelectedProjectId;
    this.dialog
      .open(FileAttachmentDialogComponent, {
        data: {
          parentResourceType,
          parentResourceId,
          allowComment: false,
          verifyFileExtension: true,
          maxFiles: 1,
          // preSelectedTags: [{ id: 6 }],
          hideTags: true,
          allowSearchFromProject: !this.arf?.id,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((resultData: UhatFileReference[]) => {
        if (resultData) {
          for (const f of resultData) {
            const foundFileToUnlink = this.filesToUnlink.find((fileToUnlink) => fileToUnlink.file_id === f.file_id);
            if (foundFileToUnlink) {
              remove(this.filesToUnlink, (fileToUnlink) => fileToUnlink.file_id === f.file_id);
              this.existingFiles.push(f);
            } else {
              if (this.newFiles) {
                this.newFiles.push({ id: f.file_id, name: f.name });
              } else {
                this.newFiles = [{ id: f.file_id, name: f.name }];
              }
            }
          }
        }
      });
  }

  removeNewFile(file: UhatFileReference) {
    remove(this.newFiles, (f: UhatFileReference) => f.id === file.id);
  }

  removeExistingFile(file: UhatFileReference) {
    remove(this.existingFiles, (f) => f.id === file.id);
    this.filesToUnlink.push(file);
  }

  isProjectAdmin() {
    return this.authService.isProjectAdmin(
      this.data?.invoice?.project_id.toString() || this.projectService.currentSelectedProjectId,
      this.projectService.currentSelectedProject?.module_id
    );
  }

  addInvoiceAmount(): void {
    const invoiceAmountData: ArfInvoiceAmount = {
      invoice_id: this.currentInvoice?.id,
      amount: 0,
      index: this.arfInvoiceAmountIndex,
    };

    this.arfInvoiceAmounts.push(invoiceAmountData);
    this.initialArfInvoiceAmounts.push(invoiceAmountData);
    this.arfInvoiceAmountIndex++;
  }

  async confirmSubmit() {
    this.invoiceFormGroup.markAllAsTouched();
    if (!this.invoiceFormGroup.valid) {
      this.snackbar.open(`Oops, some fields need your attention.`);
    } else if (this.existingFiles.length + this.newFiles.length <= 0) {
      this.snackbar.open(`An invoice file is required`);
    } else if (
      !this.is_single_date &&
      (!this.invoice_end_date?.value || !moment(this.invoice_end_date?.value).isValid())
    ) {
      this.snackbar.open(`Invoice end date required`);
    } else {
      this.modalService
        .openConfirmationDialog({
          titleBarText: `Submit Invoice`,
          headerText: 'Submit Invoice',
          confirmationButtonText: 'Submit Invoice',
          cancelButtonText: 'Make a change',
          descriptionText:
            'Please verify that all provided information is correct. If this invoice includes any change order costs, please ensure that each change order is billed for individually.',
        })
        .subscribe((confirmation) => {
          if (confirmation) {
            this.submit();
          }
        });
    }
  }

  async submit() {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Submitting Invoice..');
    const invoiceToSend: Invoice = this.currentInvoice;
    let newInvoice;

    let action;
    let invoiceId = invoiceToSend?.id;
    if (invoiceId) {
      delete invoiceToSend.id;
      if (this.updateInvoice) {
        newInvoice = await this.projectService.updateInvoice(invoiceId, invoiceToSend, ['revision']).toPromise();
      } else {
        newInvoice = this.currentInvoice;
      }
      action = 'updated';
    } else {
      if (this.currentWorkspace === Workspace.Construction && !this.arf?.id) {
        invoiceToSend.bid_package_id = this.bid_package_id.value;
      } else {
        if (!this.arf?.id) {
          invoiceToSend.quote_id = this.quote_id.value;
        } else {
          invoiceToSend.parent_id = this.arf.id;
          invoiceToSend.parent_resource_type_id = ResourceType.AcquisitionRequestForm;
        }
      }

      if (this.currentWorkspace === Workspace.Construction) {
        const title = `${this.selectedBidPackage?.awarded_company_name || this.arf?.company?.name || ''}-${
          this.change_order.value?.id ? `CO${this.change_order?.value?.local_index}` : `Original Bid`
        }${this.is_retainage.value ? ` Retainage` : ''}-Invoice ${this.invoice_number.value}`;
        this.title.setValue(title);
        invoiceToSend.title = this.title.value;
      }

      newInvoice = await this.projectService.createInvoice(invoiceToSend).toPromise();
      invoiceId = newInvoice.id;
      action = 'created';
    }
    if (invoiceId) {
      for (const f of this.newFiles) {
        await this.fileService.linkFile(f.id, invoiceId, ResourceType.Invoice).toPromise();
        await this.fileService.addTags(f.id, [6]).toPromise();
      }
      for (const f of this.filesToUnlink) {
        await this.fileService
          .getBridgeFile(f.id, invoiceId, ResourceType.Invoice)
          .toPromise()
          .then(async (bridge) => {
            for (const file of bridge) {
              await this.fileService.unlinkFile(file.id).toPromise();
            }
          });
        this.fileService.removeTags(f.file_id || f.id, [6]).subscribe();
      }

      newInvoice = { ...this.data?.invoice, ...newInvoice };
      newInvoice.files = [...(newInvoice.files || []), ...(this.newFiles || [])].filter(
        (file) => !this.filesToUnlink?.find((unlinkedFile) => unlinkedFile.id === file.id)
      );

      for (const a of this.arfInvoiceAmounts || []) {
        if (a.id) {
          if (a.update_required) {
            const id = a.id;
            delete a.id;
            delete a.update_required;
            delete a.invoice_id;
            delete a.sub_cost_code_budget;
            await this.arfService.updateArfInvoiceAmount(id, a).toPromise();
          }
        } else if (a.amount) {
          delete a.index;
          delete a.sub_cost_code_budget;
          a.invoice_id = invoiceId;
          await this.arfService.createArfInvoiceAmount(a).toPromise();
        }
      }

      for (const a of this.arfInvoiceAmountsToDelete) {
        await this.arfService.deleteArfInvoiceAmount(a.id).toPromise();
      }
    }
    if (this.updateInvoice) {
      this.snackbar.open(`Invoice ${action}!`);
    }
    this.progressIndicatorService.close();
    this.dialogRef.close(newInvoice);
  }

  protected readonly Number = Number;
  protected readonly Workspace = Workspace;
}
