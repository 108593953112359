<app-base-dialog [title]="'Time Entry'" (closeDialog)="cancel()" [draggable]="false"></app-base-dialog>
<div id="time-log-dialog" data-test-id="time-log-dialog" #mainScreen class="time-log-dialog project-modal p-0">
  <form [formGroup]="timeLogForm">
    <mat-dialog-content class="product-group-form">
      <section>
        <div class="row mb-4">
          <div class="col">
            <h6 class="font-weight-semibold dkblue mb-2">Staff Member</h6>
            <!-- Search staff auto complete -->
            <!-- default to current user -->
            <app-search-user-input
              #staff_member
              [userTypes]="userTypes"
              [placeholder]="searchUserPlaceHolder"
              [initialValueId]="initialWorkerId"
            >
            </app-search-user-input>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col d-flex flex-column">
            <div class="d-flex align-items-baseline mb-2">
              <h6 class="font-weight-semibold dkblue mr-auto">Date</h6>
              <button
                mat-button
                type="button"
                color="accent"
                class="ml-3 lh-22 pl-2 pr-2"
                (click)="setRelativeDateWorked(-1)"
              >
                Yesterday
              </button>
              <button
                mat-button
                type="button"
                color="accent"
                class="ml-3 lh-22 pl-2 pr-2"
                (click)="setRelativeDateWorked(0)"
              >
                Today
              </button>
            </div>
            <!-- default to today -->
            <mat-form-field appearance="outline" class="full-width">
              <input
                matInput
                placeholder="MM/DD/YYYY"
                [matDatepicker]="picker1"
                formControlName="date_worked"
                value="date_worker"
              />
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1 [calendarHeaderComponent]="customHeader"></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-3 pr-0">
            <span class="d-flex align-items-center">
              <h6 class="font-weight-semibold dkblue mb-2">Workspace</h6>
            </span>
            <mat-form-field appearance="outline" class="full-width">
              <mat-select placeholder="Choose a workspace" formControlName="workspace" (ngModelChange)="toggleParent()">
                <mat-select-trigger>
                  <span *ngIf="workspace?.value">
                    {{ workspace?.value?.name }}
                  </span>
                </mat-select-trigger>
                <!--                <mat-option class="workspace-option" [value]="null">-->
                <!--                  No Workspace-->
                <!--                </mat-option>-->
                <mat-option class="workspace-option" *ngFor="let workspace of possibleWorkspaces" [value]="workspace">
                  <mat-icon class="mr-2 dkblue">{{ workspace.icon }}</mat-icon>
                  {{ workspace.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col">
            <div class="row">
              <div *ngIf="canEditParent" class="col-3 pr-0">
                <h6 class="font-weight-semibold dkblue mb-2">WO/PRJ</h6>
                <mat-form-field appearance="outline" class="full-width">
                  <mat-select [value]="parentTypeId.value" [formControl]="parentTypeId">
                    <mat-option *ngFor="let parentType of parentTypes" [value]="parentType.value"
                      >{{ parentType.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col">
                <div class="d-flex align-items-top mb-2">
                  <h6 class="font-weight-semibold dkblue mb-0 mr-auto">
                    {{ +parentTypeId?.value === 3 ? 'Project' : 'Work Order' }}
                    <span class="gray" *ngIf="canEditParent"> (Optional) </span>
                  </h6>
                </div>

                <mat-form-field appearance="outline" class="full-width">
                  <input
                    type="text"
                    placeholder="{{ +parentTypeId?.value === 3 ? 'Search Projects' : 'Search Work Orders' }}"
                    matInput
                    [formControl]="parentSearchInputField"
                    #parentInput
                    [matAutocomplete]="auto"
                  />
                  <mat-icon
                    *ngIf="canEditParent && parentSearchInputField?.value && !isSearching"
                    class="gray pointer"
                    (click)="clearSearchString()"
                    matSuffix
                    >cancel</mat-icon
                  >
                  <mat-progress-spinner
                    *ngIf="parentSearchInputField?.value && isSearching"
                    matSuffix
                    mode="indeterminate"
                    diameter="20"
                  ></mat-progress-spinner>
                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="getParentText">
                    <mat-option *ngFor="let parent of parents" [value]="parent">
                      <div>
                        {{ getParentText(parent) }}
                      </div>
                    </mat-option>
                  </mat-autocomplete>
                  <mat-hint>If no Project or Work Order added, entry will be set as 'General' </mat-hint>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col">
            <h6 class="font-weight-semibold dkblue mb-2">Company</h6>
            <mat-form-field appearance="outline" class="full-width">
              <mat-select placeholder="Choose a company" formControlName="company">
                <mat-option *ngFor="let company of companies" [value]="company">
                  {{ company.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col">
            <h6 class="font-weight-semibold dkblue mb-2">Activity</h6>
            <!-- options for workspace time activities - below are for signage -->
            <mat-form-field appearance="outline" class="full-width">
              <mat-select placeholder="Choose an activity" formControlName="activity">
                <mat-option *ngFor="let activity of activities" [value]="activity">
                  {{ activity.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col">
            <h6 class="font-weight-semibold dkblue mb-2">Hours</h6>
            <mat-form-field appearance="outline" class="full-width">
              <mat-select formControlName="hours_worked">
                <mat-option [value]="hour" *ngFor="let hour of hoursWorked">
                  {{ hour }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col">
            <h6 class="font-weight-semibold dkblue mb-2">Minutes</h6>
            <mat-form-field appearance="outline" class="full-width">
              <mat-select formControlName="minutes_worked">
                <mat-option [value]="minutes" *ngFor="let minutes of minutesWorked">
                  {{ minutes }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col textarea">
            <h6 class="font-weight-semibold dkblue mb-2">Notes</h6>
            <app-editor #editor placeholder="Please provide details about this time entry."></app-editor>
          </div>
        </div>
      </section>
    </mat-dialog-content>
  </form>
  <mat-dialog-actions class="row pt-3 pb-4">
    <div class="col">
      <button mat-button mat-dialog-close class="gray full-width">Cancel</button>
    </div>
    <div class="col">
      <button
        mat-flat-button
        [mat-dialog-close]="true"
        color="accent"
        class="full-width"
        (click)="submit()"
        [disabled]="!validForm"
      >
        {{ timeLog?.id ? 'Save' : 'Submit' }} Time Entry
      </button>
    </div>
  </mat-dialog-actions>
</div>
