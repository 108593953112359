<app-base-dialog [title]="viewOnly ? 'View Staff' : 'Edit Staff'" (closeDialog)="close()"> </app-base-dialog>
<div class="d-flex flex-column align-items-center p-5 mx-auto" *ngIf="isLoading">
  <mat-spinner color="accent" [diameter]="40"></mat-spinner>
</div>
<form mat-dialog-content class="edit-staff-dialog d-flex flex-column" [formGroup]="staffFormGroup" *ngIf="!isLoading">
  <div class="d-flex flex-column mb-4">
    <h6 class="primary">{{ workspace?.name }} Manager</h6>
    <mat-form-field appearance="outline" class="full-width">
      <mat-select formControlName="wm">
        <mat-option [value]="wm" *ngFor="let wm of workspaceManagers"
          >{{ wm.first_name }} {{ wm.last_name }}</mat-option
        >
      </mat-select>
      <mat-error *ngIf="wm?.errors?.required">{{ workspace?.name }} Manager is required</mat-error>
    </mat-form-field>
  </div>
  <div class="d-flex flex-column mb-4" *ngIf="needsDFSManager">
    <div class="row d-flex align-items-center mb-2">
      <div class="col">
        <h6 class="primary">CTO or Like Position</h6>
      </div>
      <div class="col text-right">
        <mat-slide-toggle
          *ngIf="authService.isStaffOnAnyModule"
          color="accent"
          labelPosition="before"
          matNativeControl
          formControlName="dfs_required"
          [checked]="dfsRequired.value"
          [disabled]="viewOnly"
          (change)="changeDFSData()"
        >
          <div class="dkgray">{{ dfsRequired.value ? 'Needed' : 'Not Needed' }}</div>
        </mat-slide-toggle>
      </div>
    </div>
    <mat-form-field appearance="outline" class="full-width">
      <mat-select formControlName="cto">
        <mat-option [value]="dfs" *ngFor="let dfs of dfsManagers">{{ dfs.first_name }} {{ dfs.last_name }}</mat-option>
      </mat-select>
      <mat-error *ngIf="dfs?.errors?.required">User is required</mat-error>
    </mat-form-field>
  </div>
  <div class="d-flex flex-column mb-4" *ngIf="isOneCallWorkspace">
    <h6 class="primary">1CALL Director</h6>
    <mat-form-field appearance="outline" class="full-width">
      <mat-select formControlName="ocd">
        <mat-option [value]="ocd" *ngFor="let ocd of allOCDs">{{ ocd.first_name }} {{ ocd.last_name }}</mat-option>
      </mat-select>
      <mat-error *ngIf="ocd?.errors?.required">User is required</mat-error>
    </mat-form-field>
  </div>
  <div class="d-flex flex-column mb-4">
    <h6 class="primary">{{ workspace?.name }} Account Coordinator</h6>
    <mat-form-field appearance="outline" class="full-width">
      <mat-select formControlName="ac">
        <mat-option [value]="ac" *ngFor="let ac of accountCoordinators"
          >{{ ac.first_name }} {{ ac.last_name }}</mat-option
        >
      </mat-select>
      <mat-error *ngIf="ac?.errors?.required">{{ workspace?.name }} Account Coordinator is required</mat-error>
    </mat-form-field>
  </div>
  <div class="d-flex flex-column mb-4">
    <h6 class="primary">Inventory Control Officer</h6>
    <mat-form-field appearance="outline" class="full-width">
      <mat-select formControlName="ico">
        <mat-option [value]="ico" *ngFor="let ico of allICOs">{{ ico.first_name }} {{ ico.last_name }}</mat-option>
      </mat-select>
      <mat-error *ngIf="ico?.errors?.required">Inventory Control Officer is required</mat-error>
    </mat-form-field>
  </div>
  <div class="d-flex flex-column mb-4">
    <h6 class="primary">
      <!-- {{workspace}} should go here -->
      Business Systems Director
    </h6>
    <mat-form-field appearance="outline" class="full-width">
      <mat-select formControlName="bsd">
        <mat-option [value]="b" *ngFor="let b of allBSDs">{{ b.first_name }} {{ b.last_name }}</mat-option>
      </mat-select>
      <mat-error *ngIf="bsd?.errors?.required">Business Systems Director is required</mat-error>
    </mat-form-field>
  </div>
  <div class="d-flex flex-column mb-4">
    <h6 class="primary">
      <!-- {{workspace}} should go here -->
      CFMO
    </h6>
    <mat-form-field appearance="outline" class="full-width">
      <mat-select formControlName="cfmo">
        <mat-option [value]="cmfo" *ngFor="let cmfo of allCFMOs">{{ cmfo.first_name }} {{ cmfo.last_name }}</mat-option>
      </mat-select>
      <mat-error *ngIf="cfmo?.errors?.required">CFMO is required</mat-error>
    </mat-form-field>
  </div>
  <div class="d-flex flex-column mb-4">
    <h6 class="primary">
      <!-- {{workspace}} should go here -->
      CEO
    </h6>
    <mat-form-field appearance="outline" class="full-width">
      <mat-select formControlName="ceo">
        <mat-option [value]="ceo" *ngFor="let ceo of allCEOs">{{ ceo.first_name }} {{ ceo.last_name }}</mat-option>
      </mat-select>
      <mat-error *ngIf="ceo?.errors?.required">CEO is required</mat-error>
    </mat-form-field>
  </div>
</form>
<div mat-dialog-actions align="end" class="py-3">
  <button mat-button (click)="close()">{{ viewOnly ? 'Close' : 'Cancel' }}</button>
  <button mat-flat-button color="success" [disabled]="isLoading" (click)="submit()" *ngIf="!viewOnly">
    Save Staff
  </button>
</div>
