import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AccountReviewListComponent,
  AccountsComponent,
  ArfComponent,
  ArfListComponent,
  ARManagerComponent,
  BrowserNotSupportComponent,
  CompaniesComponent,
  CompanyProfileComponent,
  ConfirmAccountComponent,
  DepartmentProfileComponent,
  DepartmentsComponent,
  DirectoryComponent,
  DispatchComponent,
  HomeComponent,
  LoginComponent,
  MeetingComponent,
  MeetingListComponent,
  NewRequestComponent,
  PageNotFoundComponent,
  PasswordResetComponent,
  ProfileComponent,
  ProjectListComponent,
  ProjectRoutingComponent,
  PurchasingBudgetListComponent,
  PurchasingComponent,
  PurchasingInvoiceListComponent,
  QuickRequestComponent,
  ReleaseNotesComponent,
  RemindersComponent,
  RequestComponent,
  RequestListComponent,
  TaskListComponent,
  TasksComponent,
  WeeklyFocusComponent,
  WorkOrderComponent,
  WorkOrderListComponent,
  WorkspaceDashboardComponent,
  WorkspaceSettingsComponent,
} from 'src/app/pages';
import { FOUND_PAGE } from './enums';
import {
  AuthGuard,
  NotTenantGuard,
  NotVendorGuard,
  ProjectGuard,
  StaffGuard,
  TaskGuard,
  UnsavedGuard,
  WorkOrderGuard,
  WorkspaceManagerGuard,
} from './guards';
import { BlankPageComponent } from './pages';
import { ConstructionRequestComponent } from './workspaces/construction/pages';

const appRoutes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: { title: '1CALL Cloud - Home' },
  },
  // // TODO: remove these redirects once we're ready for the new workspaces
  // { path: '', redirectTo: 'construction', pathMatch: 'full' },
  // TODO: uncomment this when the home component is ready
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: { title: '1CALL Cloud - Home' },
  },
  {
    path: 'dashboard',
    component: WorkspaceDashboardComponent,
    canActivate: [AuthGuard],
    data: { title: '1CALL Cloud - Dashboard' },
  },
  {
    path: 'settings',
    component: WorkspaceSettingsComponent,
    canActivate: [AuthGuard],
    data: { title: '1CALL Cloud - Settings' },
  },
  { path: 'login', component: LoginComponent, data: { title: '1CALL Cloud - Login' } },
  { path: 'browser-not-supported', component: BrowserNotSupportComponent },
  {
    path: 'confirm-account',
    component: ConfirmAccountComponent,
    data: { title: '1CALL Cloud - Confirm Account' },
  },
  {
    path: 'account-review',
    component: AccountReviewListComponent,
    canActivate: [AuthGuard],
    data: { title: '1CALL Cloud - Account Review' },
  },
  {
    path: 'ar-manager',
    component: ARManagerComponent,
    canActivate: [AuthGuard],
    data: { title: '1CALL Cloud - AR Manager' },
  },
  {
    path: 'profile/:id',
    component: ProfileComponent,
    canActivate: [AuthGuard],
    data: { title: '1CALL Cloud - Profile' },
  },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
  {
    path: 'password-reset',
    component: PasswordResetComponent,
    data: { title: '1CALL Cloud - Password Reset' },
  },
  {
    path: 'directory',
    component: DirectoryComponent,
    canActivate: [AuthGuard, StaffGuard],
    data: { title: '1CALL Cloud - Directory' },
    children: [
      { path: '', component: AccountsComponent },
      { path: 'accounts', component: AccountsComponent },
      { path: 'departments', component: DepartmentsComponent },
      { path: 'companies', component: CompaniesComponent },
    ],
  },
  { path: 'user-directory', redirectTo: 'directory' },
  {
    path: 'meetings/:id',
    component: MeetingComponent,
    canActivate: [AuthGuard, NotVendorGuard],
    data: { title: '1CALL Cloud - Meeting' },
  },
  {
    path: 'meetings',
    component: MeetingListComponent,
    canActivate: [AuthGuard, NotVendorGuard, NotTenantGuard],
    data: { title: '1CALL Cloud - Meetings' },
  },
  {
    path: 'reminders',
    component: RemindersComponent,
    canActivate: [AuthGuard, StaffGuard],
    data: { title: '1CALL Cloud - Reminders' },
  },
  { path: 'dispatch', component: DispatchComponent, data: { title: '1CALL Cloud - Dispatch' } },

  // reroute all old notification routes to the new path until we can phase out those notifications
  { path: 'construction/create-request', redirectTo: 'construction-request' },
  { path: 'construction/construction-request', redirectTo: 'construction-request' },
  { path: 'construction/requests', redirectTo: 'construction/requests' },
  { path: 'construction/requests/:id', redirectTo: 'construction/requests/:id' },
  { path: 'construction/projects', redirectTo: 'projects' },
  { path: 'construction/projects/:id', redirectTo: 'projects/:id' },
  { path: 'construction/projects/:id/phase/:phase_id', redirectTo: 'projects/:id/phase/:phase_id' },
  { path: 'construction/projects/:id/tasks/:task_id', redirectTo: 'projects/:id/tasks/:task_id' },
  {
    path: 'construction/projects/:id/linked-tasks/:linked_task_id',
    redirectTo: 'projects/:id/linked-tasks/:linked_task_id',
  },
  // { path: 'projects/:id/linked-tasks/:linked_task_id', redirectTo: 'projects/:id/tasks/:linked_task_id' },
  { path: 'construction/projects/:id/:subcomponent', redirectTo: 'projects/:id/:subcomponent' },
  { path: 'construction/tasks/:task_id', redirectTo: 'tasks/:task_id' },
  { path: 'construction/linked-tasks/:linked_task_id', redirectTo: 'linked-tasks/:linked_task_id' },
  // { path: 'linked-tasks/:linked_task_id', redirectTo: 'tasks/:linked_task_id' },

  {
    path: 'quick-request',
    component: QuickRequestComponent,
    canActivate: [AuthGuard],
    data: { title: '1CALL Cloud - Quick Request' },
  },
  {
    path: 'new-request',
    component: NewRequestComponent,
    canActivate: [AuthGuard],
    data: { title: '1CALL Cloud - New Request' },
  },
  {
    path: 'construction-request',
    component: ConstructionRequestComponent,
    canActivate: [AuthGuard],
    data: { title: '1CALL Cloud - Request' },
  },
  {
    path: 'requests',
    component: RequestListComponent,
    canActivate: [AuthGuard],
    data: { title: '1CALL Cloud - Project Requests' },
  },
  {
    path: 'requests/:id',
    component: RequestComponent,
    canActivate: [AuthGuard],
    data: { title: '1CALL Cloud - Request' },
  },
  {
    path: 'projects',
    component: ProjectListComponent,
    canActivate: [AuthGuard],
    data: { title: '1CALL Cloud - Projects' },
  },
  {
    path: 'projects/:id/phase/:phase_id',
    component: ProjectRoutingComponent,
    canActivate: [AuthGuard],
    data: { title: '1CALL Cloud - Project' },
  },
  {
    path: 'projects/:id/tasks/:task_id',
    component: ProjectRoutingComponent,
    canActivate: [AuthGuard, ProjectGuard],
    data: { title: '1CALL Cloud - Project Tasks' },
  },
  {
    path: 'projects/:id/linked-tasks/:linked_task_id',
    component: ProjectRoutingComponent,
    canActivate: [AuthGuard, ProjectGuard],
    data: { title: '1CALL Cloud - Project Tasks' },
  },
  {
    path: 'tasks/:task_id',
    component: TasksComponent,
    canActivate: [AuthGuard, TaskGuard],
    data: { title: '1CALL Cloud - Project Tasks' },
  },
  {
    path: 'linked-tasks/:linked_task_id',
    component: TasksComponent,
    canActivate: [AuthGuard, TaskGuard],
    data: { title: '1CALL Cloud - Project Tasks' },
  },
  {
    path: 'projects/:id/:subcomponent',
    component: ProjectRoutingComponent,
    canActivate: [AuthGuard, ProjectGuard],
    canDeactivate: [UnsavedGuard],
    data: { title: '1CALL Cloud - Project' },
  },

  { path: 'projects/:id', redirectTo: 'projects/:id/overview' },
  {
    path: 'work-orders',
    component: WorkOrderListComponent,
    canActivate: [AuthGuard],
    data: { title: '1CALL Cloud - Work Orders' },
  },
  {
    path: 'work-orders/:id',
    component: WorkOrderComponent,
    canActivate: [AuthGuard, WorkOrderGuard],
    data: { title: '1CALL Cloud - Work Order' },
  },
  {
    path: 'release-notes',
    component: ReleaseNotesComponent,
    canActivate: [AuthGuard, StaffGuard],
    data: { title: '1CALL Cloud - Release Notes' },
  },
  {
    path: 'purchasing/arfs/:id',
    component: ArfComponent,
    canActivate: [AuthGuard, StaffGuard],
    data: { title: '1CALL Cloud - ARF' },
  },
  {
    path: 'purchasing',
    component: PurchasingComponent,
    children: [
      {
        path: 'invoices',
        component: PurchasingInvoiceListComponent,
        canActivate: [AuthGuard, StaffGuard],
        data: { title: '1CALL Cloud - Invoices' },
      },
      {
        path: 'budget',
        component: PurchasingBudgetListComponent,
        canActivate: [AuthGuard, WorkspaceManagerGuard],
        data: { title: '1CALL Cloud - Cost Codes' },
      },
      {
        path: 'arfs',
        component: ArfListComponent,
        canActivate: [AuthGuard, StaffGuard],
        data: { title: '1CALL Cloud - ARF List' },
      },
      { path: '', redirectTo: 'arfs', pathMatch: 'full' },
    ],
  },
  {
    path: 'task-list',
    component: TaskListComponent,
    canActivate: [AuthGuard, StaffGuard],
    data: { title: '1CALL Cloud - Tasks' },
  },
  {
    path: 'task-list/:id',
    component: TaskListComponent,
    canActivate: [AuthGuard, StaffGuard],
    data: { title: '1CALL Cloud - Tasks' },
  },
  {
    path: 'weekly-focus',
    component: WeeklyFocusComponent,
    canActivate: [AuthGuard, StaffGuard],
    data: { title: '1CALL Cloud - Weekly Focus' },
  },
  {
    path: 'company/:id',
    component: CompanyProfileComponent,
    data: { title: '1CALL Cloud - Team Profile' },
  },
  {
    path: 'department/:id',
    component: DepartmentProfileComponent,
    data: { title: '1CALL Cloud - Team Profile' },
  },
  { path: '404', component: PageNotFoundComponent, data: { title: '1CALL Cloud - Not Found' } },
  {
    path: '401',
    component: PageNotFoundComponent,
    data: { title: '1CALL Cloud - Not Authorized', status: FOUND_PAGE.NOT_AUTHORIZED },
  },
  { path: 'blank', component: BlankPageComponent },
  { path: '**', component: PageNotFoundComponent, data: { title: '1CALL Cloud' } },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
